import React, { useState } from "react";
import styles from "./LoanApplication.module.scss";
import LoanInformation from "./LoanInformation/LoanInformation";
import BusinessInformation from "./BusinessInformation/BusinessInformation";
import Industry from "./Industry/Industry";
import Collateral from "./Collateral/Collateral";
import FinancialInformation from "./FinancialInformation/FinancialInformation";
export interface IstepLoanApp {
  handlePrev?: () => void;
  handleNext?: () => void;
  scrollToTop?: () => void;
}
const LoanApplicationMain = () => {
  const scrollToTop = () => {
    //@ts-ignore
    document.getElementById("topOfPage").scrollIntoView({
      behavior: "smooth",
    });
  };

  const [step, setStep] = useState(1);

  const title = {
    1: "Loan Information",
    2: "Business Information",
    3: "Industry",
    4: "Financial Information",
    5: "Collateral",
  };
  //progress bar styling

  const interval = 100 / Object.keys(title).length - 2.5;

  const progress = (step * interval).toFixed(2);
  const clickOnProgressBarHandler = (stepClicked: string) => {
    if (stepClicked === "Loan Information") {
      setStep(1);
    }
    if (stepClicked === "Business Information") {
      setStep(2);
    }
    if (stepClicked === "Industry") {
      setStep(3);
    }
    if (stepClicked === "Financial Information") {
      setStep(4);
    }
    if (stepClicked === "Collateral") {
      setStep(5);
    }
  };
  const steps = Object.values(title).map((stepParams, i) => {
    return (
      <div
        key={i}
        className={`cursor-pointer ${styles.barmarker}`}
        onClick={() => {
          clickOnProgressBarHandler(stepParams);
        }}
      >
        {stepParams}
      </div>
    );
  });
  const handlePrev = () => setStep((prev: number) => prev - 1);
  const handleNext = () => setStep((next: number) => next + 1);
  return (
    <div id="topOfPage">
      <div>
        <div className={`${styles.mainHeadingContainer} pb-3`}>
          <small
            className="color-mid-blue  cursor-pointer font-weight-bold  "
            onClick={() => {}}
          >
            &#8592; Go back
          </small>
          <h1 className={styles.headingLoanApplication}>
            New Loan Application
          </h1>
        </div>
        <div className={styles.progressContainer}>
          <progress
            className={styles.progress}
            max="100"
            value={progress}
          ></progress>
          <div className={styles.barmarkerContainer}>{steps}</div>
        </div>
      </div>

      {(() => {
        switch (step) {
          case 1:
            return (
              <LoanInformation
                handlePrev={handlePrev}
                handleNext={handleNext}
                scrollToTop={scrollToTop}
              />
            );
          case 2:
            return (
              <BusinessInformation
                handlePrev={handlePrev}
                handleNext={handleNext}
                scrollToTop={scrollToTop}
              />
            );
          case 3:
            return (
              <Industry
                handlePrev={handlePrev}
                handleNext={handleNext}
                scrollToTop={scrollToTop}
              />
            );
          case 4:
            return <FinancialInformation />;
          case 5:
            return <Collateral />;
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default LoanApplicationMain;
