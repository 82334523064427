import React from "react";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
interface ICustomSelectDropdownProps {
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  maxLength?: any;
  defaultValue?: string | number;
  value?: string | number;
  onChange?: any;
  extraLabel?: string;
  showRequiredIcon?: boolean | undefined;
  required?: boolean | undefined;
  className?: any;
  disabled?: boolean | undefined;
  style?: React.CSSProperties | undefined | any;
  customSelectStyle?: any;
  reference?: any;
}

const CustomSelectDropdown: React.FC<ICustomSelectDropdownProps> = ({
  name,
  children,
  label,
  errors,
  value,
  onChange,
  showRequiredIcon,
  extraLabel,
  required,
  className,
  disabled,
  style,
  customSelectStyle,
  reference,
}) => {
  return (
    <>
      <div className={`form-group ${className} `} style={style}>
        <div className="d-flex align-items-center">
          <label className={`form-label text-muted`}>{label}</label>
          {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
          {extraLabel && extraLabel.length && (
            <label className="form-label ml-2">
              <small className="crimson">{extraLabel}</small>
            </label>
          )}
        </div>
        <select
          className="form-control"
          name={name}
          ref={reference}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          style={{ borderRadius: "10px", ...customSelectStyle }}
        >
          {children}
        </select>
        <ErrorHandler errors={errors} />
      </div>
    </>
  );
};
export default CustomSelectDropdown;
