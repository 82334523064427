import { createContext, useEffect, useState, useContext } from "react";
import { decrypt } from "../helpers/encryptor";

// Create the context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [aggregatorDetails, setAggregatorDetails] = useState(null);
  const [eligibilityData, setEligibilityData] = useState(null);

  //The state hook below is strictly only for loan request
  const [borrowerSearchDetailsCorporate, setBorrowerSearchDetailsCorporate] =
    useState();
  const [borrowerSearchDetailsIndividual, setBorrowerSearchDetailsIndividual] =
    useState();
  const [currentLoanApplicationId, setCurrentLoanApplicationId] = useState();
  useEffect(() => {
    const user = sessionStorage.getItem("_aggDt");
    const result = sessionStorage.getItem("_aggEd");

    if (user && result) {
      setAggregatorDetails(JSON.parse(decrypt(user)));
      setEligibilityData(JSON.parse(decrypt(result)));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        aggregatorDetails,
        eligibilityData,
        borrowerSearchDetailsCorporate,
        borrowerSearchDetailsIndividual,
        setAggregatorDetails,
        setEligibilityData,
        setBorrowerSearchDetailsCorporate,
        setBorrowerSearchDetailsIndividual,
        currentLoanApplicationId,
        setCurrentLoanApplicationId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom Hook
export const useAuth = () => useContext(AuthContext);
