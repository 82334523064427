import { useEffect } from "react";
import {
  Route,
  withRouter,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import Dashboard from "../../Pages/Dashboard/Dashboard";
import Borrowers from "../../Pages/Borrower/Borrower";
import AuthLayout from "../AuthLayout/AuthLayout.component";
import Terms from "../../Pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "../../Pages/PrivacyPolicy/PrivacyPolicy";
import ApiAndWidget from "../../Pages/ApiAndWidget/ApiAndWidget";
import ContactPage from "../../Pages/ContactPage/ContactPage";
import Repayment from "../../Pages/Repayment/Repayment";
import AllDisbursedLoans from "../../Pages/AllAggregatorLoans/AllDisbursedLoans/AllDisbursedLoans";
import AllInvestors from "../../Pages/Investor/AllInvestors";
import PendingLoans from "../../Pages/PendingLoans/PendingLoans";
import BatchLoanRequestStatus from "../../Pages/BatchLoanRequestStatus/BatchLoanRequestStatus";
import LoanRequestLandingPage from "../../Pages/LoanRequestLandingPage/LoanRequestLandingPage";
import ROUTES from "../../helpers/ROUTES";
import UsersManagement from "../UsersManagement/UsersManagement";
import AccountAndBVNSearch from "../../Pages/AccountAndBVNSearch/AccountAndBVNSearch";
import Settings from "../../Pages/SettingsPage/Settings";
import protectPage from "../../helpers/protectPage";
import GetStartedRoutes from "../../Pages/GetStarted/GetStartedRoutes";
import { useAuth } from "../../Context/auth.context";
import Products from "../Products";
import AllInvestments from "../../Pages/AllInvestment/AllInvestments";
import EachFunderInvestment from "../../Pages/AllInvestment/EachFunderInvestment";
import Transactions from "../../Pages/Transactions/Transactions";
import WalletTermsUse from "../../Pages/WalletTermsAndUse/WalletTermsUse";
import WalletTransactions from "../../Pages/WalletTransactions/WalletTransactions";
import Approvals from "../Approvals/Approvals";
import WalletMainPage from "../../Pages/WalletTransactions/WalletMainPage";
import WalletTransactionLog from "../../Pages/WalletDisbursementTransactions/WalletTransactionLog";
import CustomerWalletTransactions from "../CustomerWalletBorrower/CustomerWalletTransactions";
import PreviewLoanOffer from "../../Pages/PreviewLoanOffer/PreviewLoanOffer";
import DealSlipStatus from "../../Pages/DealSlip/DealSlipStatus";
import DownloadUploadDealSlip from "../../Pages/DealSlip/UploadDownloadDealSlip";
import PendingDealSlips from "../../Pages/DealSlip/PendingDealSlip";
import IndividualSingleLoanRequest from "../MakeLoanRequest/SingleLoanRequest/IndividualSingleLoanRequest.component";
import CorporateSingleLoanRequest from "../MakeLoanRequest/SingleLoanRequest/CorporateSingleLoanRequest.component";
import MultipleLoanRequest from "../MakeLoanRequest/MultipleLoanRequest/MultipleLoanRequest";
import PrivateRoute from "./PrivateRoute";
import AccessDenied from "../AccessDenied/AccessDenied";
import LoanApplicationLandingPage from "../../Pages/LoanApplication/LoanApplicationLandingPage";
import LoanApplicationMain from "../LoanApplication/LoanApplicationMain";

const AuthRoutes = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { aggregatorDetails } = useAuth();

  // Check if the user is on SSO using the flag saved in the local storage during login
  // The essence of this is to ensure that all users are registered on SSO
  // Use this to disable navlinks if user isn't resgitered on SSO, also redirect user to change password page
  // Force user tochange his password if he isn't regd on SSO
  let on_sso = sessionStorage.getItem("on_sso");
  on_sso = on_sso ? JSON.parse(on_sso) : null;

  useEffect(() => {
    //check local storage for user token
    if (sessionStorage.getItem("token")) {
      if (!on_sso) {
        history.push({
          pathname: "/settings",
          state: {
            forcedChangeOfPassword: true,
          },
        });
      }
    } else {
      //redirect to login page
      history.push("/login");
    }
    // eslint-disable-next-line
  }, [history]);

  useEffect(() => {
    if (aggregatorDetails && !on_sso) {
      history.push({
        pathname: "/settings",
        state: {
          forcedChangeOfPassword: true,
        },
      });
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <div>
      <main id="content" role="main">
        {/* Content starts here */}
        <div>
          <AuthLayout>
            {/* Page Routes go here */}

            <Route path={match.path} exact component={Dashboard} />
            <Route
              path={`${match.path}${ROUTES.MAKE_LOAN_REQUEST}`}
              component={LoanRequestLandingPage}
            />
            <Route
              path={`${match.path}loan-application-process`}
              component={LoanApplicationMain}
            />
            <Route
              path={`${match.path}${ROUTES.LOAN_APPLICATION}`}
              component={LoanApplicationLandingPage}
            />

            <Route
              path={`${match.path}${ROUTES.LOAN_OFFER}`}
              component={PreviewLoanOffer}
            />
            <Route path={ROUTES.GET_STARTED} component={GetStartedRoutes} />

            <Route
              exact
              path={match.path + "batch-loan-request-status"}
              component={BatchLoanRequestStatus}
            />
            <Route
              path={match.path + "investments"}
              component={AllInvestments}
            />
            <Route
              path={match.path + "investment-details/:id"}
              component={EachFunderInvestment}
            />
            <Route path={match.path + "investor"} component={AllInvestors} />
            <Route
              path={match.path + "transactions"}
              component={Transactions}
            />
            <Route
              path={match.path + "disbursements"}
              component={AllDisbursedLoans}
            />
            <Route
              exact
              path={`${match.path}deal-slip-status/:id`}
              component={DealSlipStatus}
            />
            <Route
              exact
              path={`${match.path}pending-deal-slips`}
              component={PendingDealSlips}
            />
            <Route
              path={`${match.path}deal-slip-status/:id/download`}
              component={DownloadUploadDealSlip}
            />
            <Route path={match.path + "repayments"} component={Repayment} />
            <Route path={match.path + "borrowers"} component={Borrowers} />
            <Route path={ROUTES.SETTINGS} component={Settings} />
            <Route
              path={ROUTES.INDIVIDUAL_LOAN_REQUEST}
              component={IndividualSingleLoanRequest}
            />
            <Route
              path={ROUTES.CORPORATE_LOAN_REQUEST}
              component={CorporateSingleLoanRequest}
            />
            <Route
              path={ROUTES.BULK_LOAN_REQUEST}
              component={MultipleLoanRequest}
            />
            <PrivateRoute
              exact
              permittedRoles={["aggregator"]}
              path={ROUTES.USER_MANAGEMENT}
              component={UsersManagement}
            />
            <Route exact path={match.path + "products"} component={Products} />
            <Route
              exact
              path={match.path + "wallet"}
              component={WalletMainPage}
            />
            <Route
              exact
              path={match.path + "wallet/customer/:id"}
              component={CustomerWalletTransactions}
            />
            <Route
              exact
              path={match.path + "wallet/:id"}
              component={WalletTransactions}
            />

            <Route
              exact
              path={match.path + "wallettransactionlog/:id"}
              component={WalletTransactionLog}
            />

            <Route
              path={match.path + "pending-loans"}
              component={PendingLoans}
            />
            <Route
              path={ROUTES.PRIVACY_POLICY}
              component={PrivacyPolicy}
              exact
            />
            <Route path={ROUTES.TERMS_OF_USE} component={Terms} exact />
            <Route
              path={ROUTES.WALLET_TERMS_OF_USE}
              component={WalletTermsUse}
              exact
            />
            <Route
              path={ROUTES.BORROWER_WIDGET}
              component={ApiAndWidget}
              exact
            />
            <Route
              path={ROUTES.CONTACT_SUPPORT}
              component={ContactPage}
              exact
            />
            <Route
              path={match.path + "account-and-bvn"}
              component={AccountAndBVNSearch}
              exact
            />
            <Route
              path={match.path + "approvals"}
              component={Approvals}
              exact
            />
            <Route path="/access-denied/" component={AccessDenied} />
          </AuthLayout>
        </div>
      </main>
    </div>
  );
};

export default protectPage(withRouter(AuthRoutes));
