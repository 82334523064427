import React from "react";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import { QuestionAnswerChoice } from "./QuestionAnswerChoice";
import styles from "./LoanApplication.module.scss";
import CustomTextArea from "../CustomHTMLElements/CustomTextArea";

const QuestionTestField = ({
  id,
  isMandatory,
  question,
  questionAnswerType,
  questionAnswers,
  onOptionAnswerChanged,
  onMultipleAnswerChanged,
  onTextAnswerChanged,
  reference,
  questionsAnswered,
}: any) => {
  return (
    <>
      {(questionAnswerType === QuestionAnswerChoice.optionChoice && (
        <div className="col-12">
          <CustomSelectDropdown
            reference={reference}
            label={question}
            showRequiredIcon={isMandatory}
            name={id}
            onChange={(e: any) => onOptionAnswerChanged(e, id)}
            required={isMandatory}
          >
            <option value=""></option>
            {questionAnswers && questionAnswers.length
              ? questionAnswers.map((answers: any, _id: any) => {
                  const { id, value } = answers;
                  return (
                    <option key={id} value={id}>
                      {value}
                    </option>
                  );
                })
              : null}
          </CustomSelectDropdown>
        </div>
      )) ||
        (questionAnswerType === QuestionAnswerChoice.textAreaChoice && (
          <div className="col-12">
            <CustomTextArea
              reference={reference}
              maxLength="64"
              label={question}
              showRequiredIcon={isMandatory}
              name={id}
              required={isMandatory}
              onChange={(e: any) => onTextAnswerChanged(e, id)}
            />
          </div>
        )) ||
        (questionAnswerType === QuestionAnswerChoice.checkboxChoice && (
          <div className="col-12 mb-4">
            <label className="form-label">{question}</label>
            {isMandatory && <sup className="ml-1 crimson">*</sup>}

            {questionAnswers && questionAnswers.length
              ? questionAnswers.map((answers: any, _id: any) => {
                  return (
                    <div
                      className={`form-check ${styles.questionCheckbox}`}
                      key={_id}
                    >
                      <input
                        className={`form-check-input multichoice `}
                        ref={reference}
                        type="checkbox"
                        name={id}
                        checked={questionsAnswered.includes(
                          answers.id.toString()
                        )}
                        value={answers.id}
                        onChange={(e) => onMultipleAnswerChanged(e, "checkbox")}
                        //@ts-ignore
                        style={{ accentColor: "#a7dea8" }}
                      />
                      <label
                        className="form-check-label ml-3"
                        htmlFor={answers.value}
                      >
                        {answers.value}
                      </label>
                    </div>
                  );
                })
              : null}
          </div>
        ))}
    </>
  );
};

export default QuestionTestField;
