import React from "react";
import PageLoader from "../../PageLoader/PageLoader.component";
import { ReactComponent as DeleteIcon } from "../../../img/DeleteIcon2.svg";
import { ReactComponent as EditIcon } from "../../../img/EditIcon.svg";
const LoanInformationExistingList = ({
  isExistingLoanInformationLoading,
  setEditLoanInformation,
}: any) => {
  let loanInformationListData = [{ list: 5 }];
  return (
    <div>
      {isExistingLoanInformationLoading ? (
        <PageLoader />
      ) : (
        loanInformationListData?.length >= 1 &&
        loanInformationListData?.map((loanInfoList: any) => {
          const { list } = loanInfoList;

          return (
            <div className="my-4" key={4}>
              <div className="card-border-wrapper-medium d-flex justify-content-between align-items-center">
                <div className="mt-4">
                  <h5 className="color-dark-purple">{`Working Capital`}</h5>
                  <p className="bg-light-grey">{"N240,000,000,000"}</p>
                </div>
                <div>
                  <span
                    title="Edit Loan information List"
                    className="cursor-pointer  transition-3d-hover "
                    onClick={() => {
                      setEditLoanInformation(true);
                    }}
                  >
                    <EditIcon className={`transition-3d-hover  `} />{" "}
                  </span>
                  <span
                    title="Delete Loan information List"
                    className="cursor-pointer  transition-3d-hover "
                    data-toggle="modal"
                    data-target="#confirmationDeleteModal"
                  >
                    {" "}
                    <DeleteIcon className={`transition-3d-hover  `} />{" "}
                  </span>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default LoanInformationExistingList;
