import React, { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { appInsights } from "../../components/AppInsight/AppInsight";
import Loader from "../../components/Loader/Loader.component";
import { errorHandler } from "../../helpers/errorHandler";
import { formatMoney } from "../../helpers/formatter";
import styles from "../../components/MakeLoanRequest/LoanOfferModal/LoanOfferModal.module.scss";

interface IreqBody {
  product_id: number;
  loan_amount: number;
  loan_tenure: number;
  annual_interest_rate: number;
  aggregator_id: number;
  country_code: string | number;
}
interface ILoanOfferExistingRequest {
  scrollToTop: () => void;
  setShowLoanOfferModal: Dispatch<SetStateAction<boolean>>;
  loanTenureInDays: number;
  loanCurrency: "NGN" | "KES" | "USD" | "#" | "$" | "Ksh";
  totalPrincipal: number;
  totalRepayment: number;
  totalInterestCharged: number;
  success: boolean;
  error: boolean;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setSucess: boolean | any;
  setError: any;
  requestBody: IreqBody;
  setRequestBody: Dispatch<SetStateAction<IreqBody | null>>;
  reset: () => void;
  productName: string;
  borrowerProfile: { customer_id: number };
  resetAmountValue: () => void;
}
const LoanOfferModalExistingLoanRequest = ({
  scrollToTop,
  setShowLoanOfferModal,
  loanTenureInDays,
  loanCurrency,
  totalPrincipal,
  totalRepayment,
  totalInterestCharged,
  success,
  error,
  loading,
  setLoading,
  setSucess,
  setError,
  requestBody,
  setRequestBody,
  reset,
  productName,
  borrowerProfile,
  resetAmountValue,
}: ILoanOfferExistingRequest) => {
  const history = useHistory();
  const loanApprovedHandler = async () => {
    //Then if loan offer is accepted by aggregator  the loan form request is submitted
    setLoading(true);
    setError(false);
    setSucess(false);
    try {
      const data = await postDataWithDotNet(
        `${apiEndpoints.loanApplicationForExistingBorrowerNew}/${borrowerProfile.customer_id}`,
        requestBody
      );
      setLoading(false);

      if (data.status === true) {
        setSucess(data?.message);
        reset();
        resetAmountValue();
        setTimeout(() => {
          setSucess(false);
          setShowLoanOfferModal(false);
        }, 5000);
        scrollToTop();
        setRequestBody(null);
      } else {
        setError(data.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
        setRequestBody(null);
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "LoanOfferModalExistingLoanRequest.tsx" },
      });
      setLoading(false);
      if (error.response.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        setError(errorHandler(error));
        setTimeout(() => {
          setError(null);
          setShowLoanOfferModal(false);
        }, 5000);
        setRequestBody(null);

        scrollToTop();
      }
    }
  };
  const loanDeclinedHandler = () => {
    setRequestBody(null);

    setShowLoanOfferModal(false);
    scrollToTop();
  };
  return (
    <div
      className="popup-box"
      id="loanOfferModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="loanOfferModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-scrollable pt-5 "
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowLoanOfferModal(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div
            className={` modal-body    d-flex flex-column align-items-center justify-content-center`}
          >
            {success && (
              <p className="alert alert-success small mt-1">{success}</p>
            )}
            {error && <p className="alert alert-danger small mt-1">{error}</p>}
            <h3 className="text">Your loan offer</h3>
            <p>
              Based on the selected loan product, you <br />
              are eligible for this offer.
            </p>
            <div className={`${styles.loanProductCard} `}>
              <h4 className="pt-1 pl-1">{productName}</h4>
              <hr />
              <div className="d-flex p-2 justify-content-between">
                <div className="">
                  <h6>Loan Amount</h6>
                  <p>{formatMoney(totalPrincipal, loanCurrency)}</p>
                </div>
                <div>
                  <h6>Loan Tenure</h6>
                  <p>{loanTenureInDays} Days</p>
                </div>
              </div>
              <div className="d-flex p-2 justify-content-between">
                <div>
                  <h6>Loan Repayment</h6>
                  <p>{formatMoney(totalRepayment, loanCurrency)}</p>
                </div>
                <div className="pl-2">
                  <h6>Loan Interest Amount</h6>
                  <p>{formatMoney(totalInterestCharged, loanCurrency)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className=" d-flex justify-content-start ml-5 pt-2 pb-3">
            <button
              type="button"
              className="btn advancly-btn"
              onClick={loanApprovedHandler}
            >
              Approve
              {loading && <Loader />}
            </button>
            <button
              type="button"
              className="btn advancly-nobg-btn color-mid-blue"
              onClick={loanDeclinedHandler}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanOfferModalExistingLoanRequest;
