import React from "react";
import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
interface ICustomTextAreaProps {
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  maxLength?: any;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number | any;
  readOnly?: boolean;
  onChange?: any;
  min?: number;
  showRequiredIcon?: boolean;
  extraLabel?: string;
  bottomLabel?: string;
  rows?: number | string | any;
  reference?: any;
  disabled?: boolean | any;
  otherLabel?: string | boolean;
  otherLabelClassName?: string | undefined;
  customAreaStyle?: any;
  required?: boolean;
}
const CustomTextArea: React.FC<ICustomTextAreaProps> = ({
  name,
  placeholder,
  reference,
  label,
  errors,
  maxLength,

  defaultValue,
  minLength,
  onChange,
  showRequiredIcon,
  extraLabel,
  rows,
  bottomLabel,
  disabled,
  otherLabel,
  otherLabelClassName,
  readOnly,
  required,
  customAreaStyle = {},
}) => {
  return (
    <div className="mb-2">
      <div className="d-flex align-items-center">
        <label className="form-label text-muted">{label}</label>
        {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
        {extraLabel && extraLabel.length && (
          <label className="form-label ml-2">
            <small className="crimson">{extraLabel}</small>
          </label>
        )}
        {otherLabel && (
          <label className="form-label ml-2">
            <small className={otherLabelClassName ? otherLabelClassName : ""}>
              {extraLabel}
            </small>
          </label>
        )}
      </div>
      <div className="form-group mb-0">
        <textarea
          style={{ ...customAreaStyle, borderRadius: "10px" }}
          className="form-control"
          name={name}
          placeholder={placeholder}
          ref={reference}
          onKeyPress={enforceMaxLength}
          maxLength={maxLength}
          minLength={minLength}
          defaultValue={defaultValue}
          onChange={onChange}
          rows={rows ? rows : 3}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
        />
        {bottomLabel && bottomLabel.length && !errors && (
          <small>{bottomLabel}</small>
        )}
        <ErrorHandler errors={errors} />
      </div>
    </div>
  );
};

export default CustomTextArea;
