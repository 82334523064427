import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import { ReactComponent as DashboardIcon } from "../../svg/icons/dashboard-icon.svg";
import { ReactComponent as WalletIcon } from "../../svg/icons/Wallet-icon.svg";
import { ReactComponent as InvestmentIcon } from "../../svg/icons/investment.svg";
import { ReactComponent as InvestorIcon } from "../../svg/icons/investor.svg";
import { ReactComponent as RepaymentIcon } from "../../svg/icons/repayment-icon.svg";
import { ReactComponent as BorrowerIcon } from "../../svg/icons/borrower-icon.svg";
import { ReactComponent as LoanIcon } from "../../svg/icons/loan-icons.svg";
import { ReactComponent as SettingsIcon } from "../../svg/icons/settings-icon.svg";
import { ReactComponent as ContactSupportIcon } from "../../svg/icons/contact-support-icon.svg";
import { ReactComponent as TermsOfUseIcon } from "../../svg/icons/terms-of-use-icon.svg";
import { ReactComponent as PrivacyPolicyIcon } from "../../svg/icons/privacy-policy-icon.svg";
import { ReactComponent as UserManagementIcon } from "../../svg/icons/user-management-icon.svg";
import { ReactComponent as ApprovalIcon } from "../../svg/icons/approval.svg";
import { ReactComponent as PlusIcon } from "../../img/add_product.svg";
import { ReactComponent as GetStartedIcon } from "../../svg/icons/get-started-icon.svg";
import { ReactComponent as TransactionIcon } from "../../svg/icons/transaction-icon.svg";
import { decrypt } from "../../helpers/encryptor";
import ROUTES from "./../../helpers/ROUTES";
import {
  isActiveAggregator,
  isSubsequentAggregatorOnboarded,
} from "../../helpers/isActiveAggregator";
import { useAuth } from "../../Context/auth.context";
import SparkSidebarLogo from "../../img/sparkLogoSidebar.svg";
import AdvanclySidebarLogo from "../../img/AdvanclySidebarDefault.svg";
import EmbedSidebarLogo from "../../img/embedLogoSidebar.svg";
import SidebarDropdownItems from "./SidebarDropdownItems";
import EmbedLogoDropDownMenu from "../../img/EmbedLogoDropdown.png";
import SparkLogoDropDownMenu from "../../img/SparkLogoDropdown.png";
import AdvestLogoDropDownMenu from "../../img/AdvestLogoDropdown.png";
import ProductListModal from "./ProductListModal";
import useAggregatorProductDetails from "../../custom-hooks/useAggregatorProductsDetails";
import { errorHandler } from "../../helpers/errorHandler";
import LoaderColored from "../Loader/LoaderColored";
import { putDataWithDotNet } from "../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { useQueryCache } from "react-query";
import Alert from "../Alert/Alert.component";
import { appInsights } from "../AppInsight/AppInsight";
const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const queryCache = useQueryCache();

  const [productActiveLogo, setProductActiveLogo] =
    useState("defaultSparkLogo");
  // const [productSwitchResponseMessage, setProductSwitchResponseMessage] =
  //   useState();
  // const [success, setSuccess] = useState(false);
  const [productSwitchLoading, setProductSwitchLoading] = useState(false);
  const [productSwitchError, setProductSwitchError] = useState();
  let menuRef = useRef();

  const match = useRouteMatch();
  const roleTypes = JSON.parse(decrypt(sessionStorage.getItem("roleTypes")));
  // const userEmail = JSON.parse(decrypt(sessionStorage.getItem("email")));
  // const { data: userSignatoryData } = useAggregatorSignatories({ menu: true });
  // const signatoryApprovalMenu = userSignatoryData?.some(
  //   (data) => data?.email === userEmail
  // );

  const { eligibilityData, aggregatorDetails } = useAuth();
  const isEmbeddedLoans = aggregatorDetails?.product_types?.some(
    ({ product_type }) => {
      return product_type === "EmbeddedLoans";
    }
  );
  const isEmbeddedInvestment = aggregatorDetails?.product_types?.some(
    ({ product_type }) => {
      return product_type === "EmbeddedInvestment";
    }
  );
  const isSparkLoanForBusiness = aggregatorDetails?.product_types?.some(
    ({ product_type }) => {
      return product_type === "SparkLoanForBusiness";
    }
  );
  const IsSparkLoanForCustomers = aggregatorDetails?.product_types?.some(
    ({ product_type }) => {
      return product_type === "SparkLoanForCustomers";
    }
  );
  // SUBMIT PUT REQUEST
  const productSwitchHandler = async (e, productEnumId) => {
    e.preventDefault();
    setProductSwitchError(null);
    setProductSwitchLoading(true);
    // setSuccess(false);
    try {
      let reqBody = { product_enum_id: productEnumId };
      const res = await putDataWithDotNet(
        apiEndpoints.UPDATE_AGGREGATOR_PRODUCT_DEFAULT,
        { ...reqBody }
      );
      setProductSwitchLoading(false);
      if (res.status_code === 200) {
        // setSuccess(true);
        queryCache.invalidateQueries("getAggregatorProductDetails");
        // setProductSwitchResponseMessage(res.message);
      } else {
        // setSuccess(false);

        setProductSwitchError(res.message);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Sidebar.component.jsx" },
      });
      // setSuccess(false);

      setProductSwitchLoading(false);
      setProductSwitchError(errorHandler(error));
    }
  };

  //END OF SUBMIT OF PUT REQUEST
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  const {
    data: productDetails,
    status: productStatus,
    error: productError,
  } = useAggregatorProductDetails();
  //JSX Template
  //Product status
  let RenderedComponentDropDownMenu = null;

  if (productStatus === "loading") {
    RenderedComponentDropDownMenu = (
      <div className="d-flex justify-content-center align-items-center ">
        <LoaderColored />
      </div>
    );
  } else if (productError) {
    RenderedComponentDropDownMenu = (
      <small className="text-danger d-flex ml-3 font-weight-bold">
        {" "}
        {errorHandler(productError)}
      </small>
    );
  } else if (productDetails) {
    RenderedComponentDropDownMenu = productDetails.map(
      ({ is_default, product_display_name, product_enum_id }) => {
        return (
          <div key={product_enum_id}>
            <SidebarDropdownItems
              img={
                product_display_name === "Embed"
                  ? EmbedLogoDropDownMenu
                  : SparkLogoDropDownMenu
              }
              alt={product_display_name}
              aggregatorEmail={aggregatorDetails?.rep_personal_email}
              productActive={is_default}
              productSwitchLoader={productSwitchLoading}
              onClick={(e) => productSwitchHandler(e, product_enum_id)}
            />
          </div>
        );
      }
    );
  } else {
    RenderedComponentDropDownMenu = (
      <small className="d-flex ml-3">
        {" "}
        Product List <br /> Empty
      </small>
    );
  }
  useEffect(() => {
    productDetails &&
      productDetails.find(({ is_default, product_display_name }) => {
        return is_default && setProductActiveLogo(product_display_name);
      });
  }, [productDetails]);
  //

  return (
    <React.Fragment>
      {/* start of dropdown_menu */}
      {/* 
      {success && (
        <Alert message={productSwitchResponseMessage} type="success" />
      )} */}
      {productSwitchError && (
        <Alert message={productSwitchError} type="danger" />
      )}
      <div className={styles.menu_container} ref={menuRef}>
        <div
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className={`ml-3 mb-4 mt-3 ${styles.selectProductLogo}`}>
            {productActiveLogo === "Spark" && (
              <Link to="/">
                <img src={SparkSidebarLogo} alt="Advancly product Logo" />
              </Link>
            )}
            {productActiveLogo === "Embed" && (
              <Link to="/">
                <img src={EmbedSidebarLogo} alt="Advancly product Logo" />
              </Link>
            )}
            {productActiveLogo === "defaultSparkLogo" && (
              <Link to="/">
                <img src={AdvanclySidebarLogo} alt="Advancly product Logo" />
              </Link>
            )}
          </div>
        </div>
        <div
          className={` ${styles.dropdown_menu} ${
            open ? styles.active : styles.inactive
          }`}
        >
          <ul className={styles.dropdownItem_ul}>
            {RenderedComponentDropDownMenu}
            <a
              href="https://onelink.to/u3b6c6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SidebarDropdownItems
                img={AdvestLogoDropDownMenu}
                alt={"Advest"}
                aggregatorEmail={aggregatorDetails?.rep_personal_email}
                productActive={false}
                advestRedirectIcon={true}
              />
            </a>
          </ul>
          <hr />
          <button
            className={styles.addProduct}
            data-toggle="modal"
            data-target="#aggregatorproductModal"
          >
            <PlusIcon />
            <span>
              {aggregatorDetails?.product_types?.length > 3
                ? "Show Products"
                : "Add Product"}
            </span>
          </button>
        </div>
      </div>
      <ProductListModal />

      {/* End of dropdown menu */}
      <ul className={`${styles.listUnstyled} ${styles.components}`}>
        {
          (!isActiveAggregator(eligibilityData) ||
            !isSubsequentAggregatorOnboarded(eligibilityData)) && (
            <li>
              {/* Get Started with Onboarding */}
              <NavLink
                to={ROUTES.GET_STARTED}
                className={styles.navLink}
                activeClassName={styles.selected}
              >
                <GetStartedIcon />
                <span>Get Started</span>
              </NavLink>
            </li>
          )

          //  : !isSubsequentAggregatorOnboarded(eligibilityData) ? (
          //   <li>
          //     {/* Get Started with Onboarding */}
          //     <NavLink
          //       to={ROUTES.GET_STARTED}
          //       className={styles.navLink}
          //       activeClassName={styles.selected}
          //     >
          //       <GetStartedIcon />
          //       <span>Get Started</span>
          //     </NavLink>
          //   </li>
          // ) : (
          //   ""
          // )
        }
        {/* Dashboard */}
        <li>
          <NavLink
            className={`${styles.navLink} ${match.isExact && styles.selected}`}
            to={match.path}
          >
            <DashboardIcon />
            <span>Overview</span>
          </NavLink>
        </li>
        {productActiveLogo === "Embed" && isEmbeddedInvestment && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "investments"}
              activeClassName={styles.selected}
            >
              <InvestmentIcon />
              <span>Investments</span>
            </NavLink>
          </li>
        )}
        {productActiveLogo === "Embed" && isEmbeddedInvestment && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "investor"}
              activeClassName={styles.selected}
            >
              <InvestorIcon />
              <span>Investors</span>
            </NavLink>
          </li>
        )}
        {productActiveLogo === "Embed" && isEmbeddedInvestment && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "transactions"}
              activeClassName={styles.selected}
            >
              <TransactionIcon />
              <span> Investment Transactions</span>
            </NavLink>
          </li>
        )}
        {(isEmbeddedLoans ||
          isSparkLoanForBusiness ||
          IsSparkLoanForCustomers) && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "disbursements"}
              activeClassName={styles.selected}
            >
              <LoanIcon />
              <span>Loans</span>
            </NavLink>
          </li>
        )}
        {(isEmbeddedLoans ||
          isSparkLoanForBusiness ||
          IsSparkLoanForCustomers) && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "loan-application"}
              activeClassName={styles.selected}
            >
              <LoanIcon />
              <span>Loan Application</span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            className={styles.navLink}
            to={match.path + "pending-deal-slips"}
            activeClassName={styles.selected}
          >
            <LoanIcon />
            <span>Pending Deal Slips</span>
          </NavLink>
        </li>
        {(isEmbeddedLoans ||
          isSparkLoanForBusiness ||
          IsSparkLoanForCustomers) && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "repayments"}
              activeClassName={styles.selected}
            >
              <RepaymentIcon />
              <span>Repayments</span>
            </NavLink>
          </li>
        )}
        {(isEmbeddedLoans ||
          isSparkLoanForBusiness ||
          IsSparkLoanForCustomers) && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "borrowers"}
              activeClassName={styles.selected}
            >
              <BorrowerIcon />
              <span>Borrowers</span>
            </NavLink>
          </li>
        )}
        {(isEmbeddedLoans ||
          isSparkLoanForBusiness ||
          IsSparkLoanForCustomers) && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "pending-loans"}
              activeClassName={styles.selected}
            >
              <DashboardIcon />
              <span>Pending Loans</span>
            </NavLink>
          </li>
        )}
        {(isEmbeddedLoans ||
          isSparkLoanForBusiness ||
          IsSparkLoanForCustomers) && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "products"}
              activeClassName={styles.selected}
            >
              <DashboardIcon />
              <span>Loan products</span>
            </NavLink>
          </li>
        )}
        {(isEmbeddedLoans ||
          isEmbeddedInvestment ||
          isSparkLoanForBusiness ||
          IsSparkLoanForCustomers) && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "wallet"}
              activeClassName={styles.selected}
            >
              <WalletIcon />
              <span>Wallet</span>
            </NavLink>
          </li>
        )}

        <li>
          <NavLink
            className={styles.navLink}
            to={match.path + "approvals"}
            activeClassName={styles.selected}
          >
            <ApprovalIcon />
            <span>Approvals</span>
          </NavLink>
        </li>
        {/* )} */}
        {/* )} */}
        {/* {(aggregatorDetails?.aggregator_type.toLowerCase() === "both" ||
          aggregatorDetails?.aggregator_type.toLowerCase() === "lending") && (
          <li>
            <NavLink
              className={styles.navLink}
              to={match.path + "account-and-bvn"}
              activeClassName={styles.selected}
            >
              <AccountBvnSearchIcon />
              <span>Account Search</span>
            </NavLink>
          </li>
        )} */}
        {/* <li>
          <NavLink
            className={styles.navLink}
            to={ROUTES.BORROWER_WIDGET}
            activeClassName={styles.selected}
          >
            <ApiWidgetIcon />
            <span>API & Widgets</span>
          </NavLink>
        </li> */}
        {/* <div className={styles.sidebarLine}></div> */}
        <li>
          <NavLink
            className={styles.navLink}
            to={ROUTES.SETTINGS}
            activeClassName={styles.selected}
          >
            <SettingsIcon />
            <span>Settings</span>
          </NavLink>
        </li>
        {roleTypes && roleTypes.includes("aggregator") && (
          <li>
            <NavLink
              className={styles.navLink}
              to={ROUTES.USER_MANAGEMENT}
              activeClassName={styles.selected}
            >
              <UserManagementIcon />
              <span>Users Management</span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink className={styles.navLink} to={ROUTES.CONTACT_SUPPORT}>
            <ContactSupportIcon />
            <span>Contact Support</span>
          </NavLink>
        </li>
        {/* <div className={`${styles.sidebarLine} ${styles.fullWidth}`}></div> */}
        {/* Footer Section */}
        <li>
          <NavLink
            className={styles.navLink}
            to={ROUTES.PRIVACY_POLICY}
            activeClassName={styles.selected}
          >
            <PrivacyPolicyIcon />
            <span>Privacy Policy</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.navLink}
            to={ROUTES.TERMS_OF_USE}
            activeClassName={styles.selected}
          >
            <TermsOfUseIcon />
            <span>Terms Of Use</span>
          </NavLink>
        </li>
      </ul>
    </React.Fragment>
  );
};
export default Sidebar;
