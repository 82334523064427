export const apiEndpoints = {
  resetPasswordUrl: `/account/reset_password/`,
  loginUrl: `/account/custom_login/`,
  changePasswordUrl: `/account/change_password/`,
  signUpUrl: `/account/signup_aggregator/`,
  verifyEmail: `/account/verify`,
  VALIDATE_OTP: `/account/otp/verify`,
  RESET_OTP: `/account/otp/generate`,
  businessCategoriesUrl: `/misc/list_category/`,
  businessSectors: `/misc/sectors`,
  countryCodesUrl: `/account/all_country/`,
  getBanksList: `/account/signed_banks`,
  getWalletDetails: `/account/aggregator/wallet`,
  getAggregatorStates: `/account/all_state`,
  getBanksListByCountry: `/account/signed_banks_country`,
  getAggregatorAccounts: `/account/aggr_my_bank`,
  getAggregatorDetails: `/account/aggr_main_details`,
  getBatchLoanRequestStatus: `/eco/upload_batch_loan_list`,
  updateABatchLoanRecord: `/eco/update_batch_loan_record`,
  getAggregatorProducts: `/misc/query_product_by_aggregator/`,
  getLoanProductDetails: `/account/loan_products/detail`,
  // transactionsOverview: `/misc/aggr_tx_overview/`,
  transactionsOverview: `/misc/aggregator_loan_summary`,
  collectionsOverview: `/misc/aggr_tx_collection/`,
  dashboardActivities: `/eco/list_aggregator_actions/`,
  dashboardDisbursementsGraph: `/statistics/aggregator/disbursement/graph`,
  dashboardRepaymentsGraph: `/statistics/aggregator/repayment/graph`,
  dashboardBorrowersGraph: `/statistics/aggregator/borrower/graph`,
  getRecentDisbursements: `/statistics/aggregator/recent_disbursement`,
  getRecentBorrowers: `/statistics/aggregator/recent_borrowers`,
  getRecentRepayments: `/eco/list_aggregator_recent_repayments`,
  repayLoans: `/eco/aggr_repay_loan/`,
  aggregatorDetails: `/account/boro_major_details/`,
  approveBatchLoans: `/eco/approve_makerchecker_transaction_batch`,
  declineBatchLoans: `/eco/reject_makerchecker_transaction_batch`,
  batchLoanCreation: `/eco/batch_loan_creation`,
  loanApplicationDirect: `/account/loan_application`,
  loanApplicationForExistingBorrower: `/eco/request_loan`,
  loanApplicationForExistingBorrowerNew: `eco/request_loan_existing_customers`,
  getGeneratedAggregatorKey: `/account/get_aggregator_details`,
  getBorrowerDetails: `/account/aggr_boro_people/`,
  getAllABorrowerRepayments: `/eco/boro_man_repayment`,
  getAllABorrowerLoans: `/eco/list_borrower_loan`,
  updateTrustScore: `/account/aggr_update_score/`,
  getAllRepayments: `/eco/list_aggregator_repayment/`,
  getAllRepaymentsReport: `/eco/repayment_schedule_report`,
  getAllDueRepayments: `/eco/outstanding_repayment_schedule_report`,
  // getAllAutoRepaymentDetails: `/eco/repayment/automated`,
  getAllClosedLoans: `/eco/search_loan`,

  autoRepayment: `/eco/repayment/automated/initiate`,
  finalAutoRepayment: `/eco/repayment/automated/finish`,
  // getAllDisbursement: `/eco/list_aggregator_loan/`,
  getAllDisbursement: `/eco/aggregator_loan_list`,
  initiateLoanRollOver: `/eco/initiate_loan_rollover`,
  getRollOverDetails: `/eco/list_rollover_loans`,
  getAllPendingLoans: `/eco/list_pending_borrower_loans`,
  getAggregatorUsers: `/account/aggregator_user`,
  createAggregatorUser: `/account/aggregator_user`,
  toggleAggregatorUserStatus: `/account/toggle_aggregator_user_active_status`,
  updateAggregatorUserPhoto: `/account/aggr_update_profile/`,
  updateAggregatorUserInfo: `/account/update_aggregator`,
  bvnValidation: `/account/run_bvn_validation`,
  accountNumberValidation: `/account/run_account_validation`,
  getSearchHistory: `/account/query_aggregator_kyc`,
  queryLoanDetails: `/eco/query_loan_detail/`,
  withdrawFromOverdraft: `/eco/overdraft_withdrawal`,
  getAllEligibilityQuestions: `/eligibility/aggregator/questions`,
  addAggregatorBusinessInfo: `/account/customer_business_info`,
  addAggregatorEligibilityTest: `/eligibility/aggregator/questions/answer`,
  getListOfDocumentsToUpload: `/document`,
  getListOfDocumentsAlreadyUploaded: `/account/aggregator_document_upload_multiple`,
  reuploadCategoryFile: `/account/aggregator_document_reupload_multiple`,
  getListOfDocumentsAlreadyUploadedSingle: `/account/aggregator_document_upload`,
  toggleEnvironment: `/account/aggregator_switch_account`,
  getEligibilityStatus: `/account/get_aggregator_onboarding_status`,
  onboardAggregatorRequest: `/account/aggregator_request_onboarding`,
  cancelOnboardingRequest: `/account/aggregator_products/onboarding/cancel`,
  offerLetterAndSla: `/account/offerletter_sla_upload`,
  offerLetterAndSlaReupload: `/account/offerletter_sla_reupload`,
  OfferLetterAndSlaHistory: `/account/offerletter_sla_upload/history`,
  DOWNLOAD_AGGREGATOR_UPLOADED_FILE: `/account/document_download`,
  DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64: `/account/document_download_base64`,
  DOWNLOAD_DEAL_SLIP: "/dealslip/aggregator/download",
  ALL_FUNDER: "/account/query_funder",
  getAllInvestments: "/account/query_investment",
  getAllTransactions: "/account/query_aggregator_transaction",
  getAllWalletTransactions: "/wallet/transactions",
  getAllWallet: "/wallet/accounts",
  INVESTORS_INVESTMENT_SUMMARY: "/statistics/funder_investment/summary",
  INVESTORS_INVESTMENT: "/account/get_funder_investments",
  EACH_INVESTOR_INVESTMENT_SUMMARY:
    "/statistics/funder_investment/investment_product_summary",
  getDueLoansCount: "/eco/outstanding_repayments/count",
  GET_ALL_SECURITY_QUESTIONS: "/account/security_question/all",
  SAVE_SECURITY_QUESTION: "/account/security_question/answers",
  SAVE_SECURITY_PIN: "account/pin/create",
  GET_SAVED_SECURITY_QUESTION: "/account/security_question",
  CONFIRM_SECURITY_QUESTION: "/account/security_question",
  WORLD_COUNTRY_CODES: "/account/all_world_countries",
  ADVANCLY_SPECIFIC_COUNTRIES: "/account/advancly_specific_countries",
  COUNTRY_STATES: "/account/country_states",
  ASSIGN_EDIT_AGGREGATOR_ROLE: "/account/aggregator_user_role",
  AGGREGATOR_ROLES: "/account/aggregator_roles",
  SIGNATORIES_SETUP: "/account/transaction_signatories_maker_checker",
  CHANGE_SIGNATORIES: "/account/change_transaction_signatories_request",
  GET_AGGREGATOR_SIGNATORIES: "/account/transaction_signatories_maker_checker",
  GENERATE_OTP: "/account/generate_otp",
  GET_AGGREGATOR_SIGNATORY_USERS: "/account/aggregator_signatory_users",
  VALIDATE_BANK_ACCOUNT: "/account/validate_bank_account",
  INITIATE_DISBURSEMENT:
    "/account/aggregator/initiate_disbursement_maker_checker",
  GET_ALL_WALLET: "/wallet/accounts",
  PENDING_DISBURSEMENT:
    "/account/aggregator/pending_disbursement_maker_checker",
  WALLET_INFORMATION: "/wallet/information",
  APPROVE_DISBURSMENT: "/account/aggregator/approve_disbursement_maker_checker",
  DECLINE_DISBURSMENT: "/account/aggregator/decline_disbursement_maker_checker",
  WALLET_TRANSACTION_LOG:
    "/account/aggregator/wallet_transaction_log_maker_checker",
  WALLET_DISBURSEMENT_TRANSACTIONS: "/account/aggregator/wallet_transactions",
  GENERAL_BROADCAST_HUB: "/inapppush/broadcast",
  SPECIFIC_BROADCAST_HUB: "/inapppush/specific",
  NOTIFICATION_LIST: "notification/user_message/list",
  SET_NOTIFICATION_AS_READ: "notification/user_message_set_read",
  USER_ACTIVE_CURRENCIES: "/account/aggregator/loan_product_currencies",
  LOAN_COUNT: "/statistics/aggregator/total_loan",
  TOTAL_OUTSTANDING_LOANS: "/statistics/aggregator/total_outstanding_loans",
  GLOBAL_WAITLIST: "/misc/global_wait_list",
  PRODUCT_TYPE: "/account/aggregator_products_setup",
  PENDING_DEAL_SLIP: "/dealslip/loan/",
  PENDING_DEAL_SLIP_LIST: "/dealslip/pending_list",
  SEND_SIGNED_SLIP: "/dealslip/aggregator/upload_signed_slip",
  DEAL_SLIP_COMMENTS: "/dealslip/comment",
  AGGREGATOR_PRODUCT_DETAILS: "/account/aggregator_products_detail",
  UPDATE_AGGREGATOR_PRODUCT_DEFAULT: "/account/aggregator_product/default",
  PRODUCT_TYPE_REMAINING: "/account/aggregator_products_detail/new",
  AGGREGATOR_SUBSEQUENT_PRODUCT_ONBOARDING:
    "/account/aggregator_products/onboarding",
  AGGREGATOR_INVESTMENT_SUMMARY: "/misc/aggregator_investment_summary",
  AGGREGATOR_INVESTMENT_GRAPH: "/statistics/aggregator/investment/graph",
  AGGREGATOR_RECENT_INVESTMENTS: "/statistics/aggregator/recent_investments",
  AGGREGATOR_INVESTMENT_INTEREST_GRAPH:
    "/statistics/aggregator/investment_interests/graph",
  AGGREGATOR_RECENT_INVESTMENTS_INTEREST:
    "/statistics/aggregator/recent_investment_interests",
  AGGREGATOR_INVESTORS_GRAPH: "/statistics/aggregator/investors/summary",
  AGGREGATOR_RECENT__INVESTORS:
    "/statistics/aggregator/investment/recent_investors",
  DOCUMENT_COMMENTS: "/document/file_comment",
  AGGREGATOR_API_KEYS: "/account/aggregator_api_keys",
  GENERATE_AGGREGATOR_API_KEYS: "/account/generate_aggregator_keys",
  LOAN_OFFER_SCHEDULE: "/eco/generate_loan_schedule",
  SEARCH_FOR_BORROWER: "/account/borrower_search",
  WEB_HOOK_EVENT: "/webhook/event",
  SUBSCRIBE_WEB_EVENT: "/webhook/subscription/subscribe",
  WEB_HOOK_LIST: "/webhook/subscription/list",
  UNSUBSCRIBE_WEB_HOOK: "/webhook/subscription/unsubscribe/",
  UPDATE_WEB_HOOK_SUBSCRIPTION: "/webhook/subscription/update/",
  WEB_HOOK_HISTORY: "/webhook/history",
  CONTACT_FORM: "/misc/contact_form",
  FUNDER_PEOPLE: "/customer/funder_people",
  GOVERNMENT_DOCUMENT_TYPES: "/enum/government_issued_ids",
  SHAREHOLDER_ONBOARDING: "/customer/shareholders",
  GENERAL_AVAILABLE_CURRENCIES: "/currencies",
  LOAN_APPLICATIONS: "account/loan_applications",
  LOAN_APPLICATION_QUESTIONS:
    "/eligibility/aggregator/questions/loan_application",
  LOAN_APPLICATION_BUSINESS: "account/loan_applications/business",
  LOAN_APPLICATION_INDUSTRY: "account/loan_applications/industry",
};
