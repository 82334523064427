import React, { useState } from "react";
import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import { NumericFormat } from "react-number-format";

interface ICustomInputFieldProps {
  type: string;
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  maxLength?: any;
  max?: number | string | any;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number;
  readOnly?: boolean;
  onChange?: any;
  min?: number;
  showRequiredIcon?: boolean;
  step?: number | string;
  hasActionButton?: boolean;
  required?: boolean | any;
  disableAutoComplete?: boolean | any;
  disabled?: boolean;
  className?: string | any;
  extraLabel?: string;
  enableSeparator?: boolean;
  customInputStyle?: any;
  style?: React.CSSProperties | undefined | any;
  reference?: any;
}

const CustomInputField: React.FC<ICustomInputFieldProps> = ({
  type,
  name,
  placeholder,
  label,
  errors,
  maxLength,
  max,
  defaultValue,
  minLength,
  onChange,
  readOnly,
  extraLabel,
  min,
  showRequiredIcon,
  step,
  hasActionButton,
  required,
  disableAutoComplete,
  disabled,
  children,
  className,
  value,
  enableSeparator = false,
  style,
  customInputStyle,
  reference,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`mb-4 ${className}`} style={style}>
      <div className="d-flex align-items-center">
        {label && <label className={`form-label text-muted`}>{label}</label>}
        {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
        {extraLabel && extraLabel.length && (
          <label className="form-label ml-2">
            <small className="crimson">{extraLabel}</small>
          </label>
        )}
      </div>
      <div className="input-group ">
        {children && (
          <div className="input-group-prepend">
            <span className="input-group-text">{children}</span>
          </div>
        )}

        {enableSeparator ? (
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            allowLeadingZeros
            value={value}
            defaultValue={defaultValue}
            className={`form-control  ${hasActionButton && "border-right-0"} ${
              children && "border-left-0 "
            } `}
            style={{
              borderRadius: hasActionButton ? "10px 0px 0px 10px" : "10px ",
            }}
            name={name}
            max={max}
            min={min}
            placeholder={placeholder}
            getInputRef={reference}
            onKeyPress={enforceMaxLength}
            maxLength={maxLength}
            minLength={minLength}
            onValueChange={onChange}
            readOnly={readOnly}
            step={step}
            required={required}
            autoComplete={disableAutoComplete ? "new-password" : ""}
            disabled={disabled}
          />
        ) : (
          <input
            className={`form-control  ${hasActionButton && "border-right-0"} ${
              children && "border-left-0 "
            } `}
            style={{
              borderRadius: hasActionButton ? "10px 0px 0px 10px" : "10px ",
              ...customInputStyle,
            }}
            type={
              showPassword
                ? showPassword === true
                  ? "text"
                  : "password"
                : type
            }
            name={name}
            max={max}
            min={min}
            placeholder={placeholder}
            ref={reference}
            onKeyPress={enforceMaxLength}
            maxLength={maxLength}
            minLength={minLength}
            defaultValue={defaultValue}
            onChange={onChange}
            readOnly={readOnly}
            step={step}
            required={required}
            autoComplete={disableAutoComplete ? "new-password" : ""}
            disabled={disabled}
            value={value}
          />
        )}

        {hasActionButton && (
          <div
            className="input-group-append  cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            <span
              className="input-group-text text-capitalize "
              style={{
                borderRadius: "0px 10px 10px 0px",
              }}
            >
              {showPassword ? (
                <i className="fa fa-eye"></i>
              ) : (
                <i className="fa fa-eye-slash"></i>
              )}
            </span>
          </div>
        )}
      </div>
      <ErrorHandler errors={errors} />
    </div>
  );
};
export default CustomInputField;
