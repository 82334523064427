import React, { useEffect, useState } from "react";
import styles from "../LoanApplication.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { loanRequestSchema } from "../../../helpers/loanRequestSchema";
import { postData } from "../../../apis/apiMethods";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import Loader from "../../Loader/Loader.component";
import QuestionTestField from "../QuestionTestField";
import useLoanApplicationQuestions from "../../../custom-hooks/useLoanApplicationQuestions";
import { useAuth } from "../../../Context/auth.context";
import PageLoader from "../../PageLoader/PageLoader.component";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { handleFormatAmountNoCurrency } from "../../../helpers/formatterNoDecimal";
import CustomNestedInputField from "../../CustomHTMLElements/CustomNestedInputField";
import useGeneralCurrencies from "../../../custom-hooks/useGeneralCurrencies";
import LoaderColored from "../../Loader/LoaderColored";
import { IstepLoanApp } from "../LoanApplicationMain";

const schema = yup.object().shape({
  annualTurnover: loanRequestSchema.loanAmount,
});
interface Iquestion {
  position: number;
}
interface QuestionsSort {
  conditionalAnswerId: number;
  conditionalQuestionId: number;
  id: number;
  isConditional: number;
  isMandatory: boolean;
  question: string;
  questionAnswerType: string;
  questionAnswers: [];
}
interface ISubmitHandler {
  annualTurnover: number;
  currency: string;
}
const BusinessInformation = ({
  handlePrev,
  handleNext,
  scrollToTop,
}: IstepLoanApp) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [arrayOfAnswers, setArrayOfAnswers] = useState<any>([]);
  const [questionsAnswered, setQuestionsAnswered] = useState<any>([]);
  const [selectedCurrencyValue, setSelectedCurrencyValue] = useState("");
  const [amountValue, setAmountValue] = useState("");

  const { aggregatorDetails, currentLoanApplicationId } = useAuth();

  const handleSelectChange = (event: any) => {
    setSelectedCurrencyValue(event.target.value);
  };
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    data: questionData,
    status: questionStatus,
    error: questiorErrors,
  } = useLoanApplicationQuestions({
    aggregatorID: aggregatorDetails?.aggregator_id,
    questionDomainCategory: 1,
  });
  const {
    data: currencyData,
    status: currencyStatus,
    error: currencyErrors,
  } = useGeneralCurrencies();
  const onOptionAnswerChanged = (e: any, id: any) => {
    const { name, value } = e.target;
    const remainingObjects = arrayOfAnswers.filter(
      (answer: any) => Number(answer.questionId) !== Number(name)
    );
    setArrayOfAnswers([
      ...remainingObjects,
      {
        questionId: Number(name),
        questionAnswerId: [Number(value)],
        comment: "",
      },
    ]);
  };
  const onTextAnswerChanged = (e: any, id: any) => {
    const { name, value } = e.target;
    const remainingObjects = arrayOfAnswers.filter(
      (answer: any) => Number(answer.questionId) !== Number(name)
    );
    setArrayOfAnswers([
      ...remainingObjects,
      {
        questionId: Number(name),
        questionAnswerId: [],
        comment: value,
      },
    ]);
  };

  const onMultipleAnswerChanged = (e: any, inputType: any) => {
    const { name, value } = e.target;
    if (inputType && inputType === "checkbox") {
      if (!questionsAnswered.includes(value)) {
        setQuestionsAnswered([...questionsAnswered, value]);
      } else {
        setQuestionsAnswered(questionsAnswered.filter((v: any) => v !== value));
      }

      const fileToEdit = arrayOfAnswers.find(
        (answer: any) => Number(answer.questionId) === Number(name)
      );
      const remainingObjects = arrayOfAnswers.filter(
        (answer: any) => Number(answer.questionId) !== Number(name)
      );

      if (e.target.checked) {
        setArrayOfAnswers([
          ...remainingObjects,
          {
            questionId: Number(name),
            comment: "",
            questionAnswerId: fileToEdit
              ? [...fileToEdit.questionAnswerId, Number(value)]
              : [Number(value)],
          },
        ]);
      } else {
        setArrayOfAnswers([
          ...remainingObjects,
          {
            ...fileToEdit,
            questionAnswerId: [
              ...fileToEdit.questionAnswerId.filter(
                (answer: any) => Number(answer) !== Number(value)
              ),
            ],
          },
        ]);
      }
    }
  };

  // useEffect(() => {
  //   // this effect is only use to store state of user information
  //   //This was done so when the user is to go back after an error the filled value would be there
  //   if (generalEligbilityAnswers) {
  //     let dataObjects = Object.entries(generalEligbilityAnswers);
  //     for (const [key, value] of dataObjects) {
  //       setValue(key, value);
  //     }
  //   }
  // }, [generalEligbilityAnswers, setValue]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "BusinessInformation.tsx",
      isLoggedIn: true,
    });
  }, []);
  const onSubmitHandler = async (data: ISubmitHandler) => {
    const { annualTurnover, currency } = data;
    const RequestBody = {
      loan_application_id: currentLoanApplicationId,
      turnover_amount: +handleFormatAmountNoCurrency(String(annualTurnover)),
      turnover_currency: currency ? currency : selectedCurrencyValue,
      question_answers: arrayOfAnswers,
    };

    try {
      setLoading(true);
      setError(null);
      const response = await postData(
        apiEndpoints.LOAN_APPLICATION_BUSINESS,
        RequestBody
      );
      setLoading(false);
      setSuccess(response?.message);
      if (scrollToTop) {
        scrollToTop();
      }
      setTimeout(() => {
        if (handleNext) {
          handleNext();
        }
        setSuccess(null);
        setError(null);
      }, 3000);
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "BusinessInformation.tsx",
        },
      });
      setLoading(false);
      setSuccess(null);
      setError(errorHandler(err));
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  if (questionStatus === "loading")
    return (
      <div className="d-flex justify-content-center align-items-center my-auto">
        {" "}
        <PageLoader />
      </div>
    );
  return (
    <div className={`${styles.center_container} `}>
      <div className="py-3">
        <h2 className={`${styles.InformationHeading}`}>Business Information</h2>
        <p className={`${styles.InfoParagraph}`}>Describe your business</p>
      </div>
      <div>
        {questiorErrors && (
          <p className="alert alert-danger small">{questiorErrors}</p>
        )}
        {currencyErrors && (
          <p className="alert alert-danger small">
            {errorHandler(currencyErrors)}
          </p>
        )}
        {success && <p className="alert alert-success small">{success}</p>}
        {error && <p className="alert alert-danger small">{error}</p>}
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="row">
          <div className="col-12">
            <CustomNestedInputField
              type="number"
              reference={register}
              name="annualTurnover"
              value={amountValue}
              placeholder="E.g N240,000,000,000"
              label="Annual Turnover"
              errors={errors.annualTurnover}
              enableSeparator
              onChange={(values: any) => {
                const { formattedValue } = values;
                setAmountValue(formattedValue);
              }}
            >
              <select
                name="currency"
                ref={register}
                value={selectedCurrencyValue}
                onChange={handleSelectChange}
                style={{ backgroundColor: "#D1CFD6", color: "#170D33" }}
              >
                {currencyData &&
                  currencyData.length &&
                  currencyData?.map(
                    ({ currency_code }: { currency_code: string }) => (
                      <option key={currency_code} value={currency_code}>
                        {currency_code}
                      </option>
                    )
                  )}
              </select>
            </CustomNestedInputField>
            {currencyStatus === "loading" && (
              <div className=" d-flex justify-content-end">
                {" "}
                <LoaderColored />{" "}
              </div>
            )}
          </div>
          {questionData && questionData.length ? (
            questionData
              .sort((a: Iquestion, b: Iquestion) => a.position - b.position)
              .map((obj: QuestionsSort, _id: number) => {
                const {
                  conditionalAnswerId,
                  conditionalQuestionId,
                  id,
                  isConditional,
                  isMandatory,
                  question,
                  questionAnswerType,
                  questionAnswers,
                } = obj;
                return (
                  <div className="col-xs-12  col-md-12  pl-0 pr-0  " key={_id}>
                    {!isConditional ? (
                      <QuestionTestField
                        id={id}
                        isMandatory={isMandatory}
                        question={question}
                        questionAnswerType={questionAnswerType}
                        questionAnswers={questionAnswers}
                        onOptionAnswerChanged={onOptionAnswerChanged}
                        onMultipleAnswerChanged={onMultipleAnswerChanged}
                        onTextAnswerChanged={onTextAnswerChanged}
                        reference={register}
                        questionsAnswered={questionsAnswered}
                      />
                    ) : (
                      ""
                    )}
                    {/* this is used only when there is a checkbox or radio which contains options */}
                    {isConditional &&
                    arrayOfAnswers &&
                    arrayOfAnswers.length > 0
                      ? // eslint-disable-next-line array-callback-return
                        arrayOfAnswers
                          .filter(
                            (answer: any) =>
                              Number(answer.questionId) ===
                                conditionalQuestionId &&
                              Number(
                                answer.questionAnswerId.includes(
                                  conditionalAnswerId
                                )
                              )
                          )
                          .map(() => {
                            return (
                              <div key={_id}>
                                <QuestionTestField
                                  id={id}
                                  isMandatory={isMandatory}
                                  question={question}
                                  questionAnswerType={questionAnswerType}
                                  questionAnswers={questionAnswers}
                                  onOptionAnswerChanged={onOptionAnswerChanged}
                                  onMultipleAnswerChanged={
                                    onMultipleAnswerChanged
                                  }
                                  onTextAnswerChanged={onTextAnswerChanged}
                                  reference={register}
                                  questionsAnswered={questionsAnswered}
                                />
                              </div>
                            );
                          })
                      : ""}
                  </div>
                );
              })
          ) : (
            <div className="w-100 p-5">
              <h3 className="text-center">
                Business Information questions not available please contact
                admin.
              </h3>
            </div>
          )}
          <div className={`col-12`}>
            <button
              type="submit"
              className="advancly-btn  transition-3d-hover m-2"
              disabled={loading}
            >
              Continue
              {loading && <Loader />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BusinessInformation;
