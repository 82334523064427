import * as yup from "yup";

// Regular expression for phone number
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const numberRegExp = /[0-9]/;
export const yupValidators = {
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Password must have at least 8 characters")
    .max(128, "Password cannot be longer than 128 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(numberRegExp, "Password must contain a number"),
  newPassword: yup
    .string()
    .required("Please enter your new password")
    .min(8, "Password must have at least 8 characters")
    .max(128, "Password cannot be longer than 128 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(numberRegExp, "Password must contain a number"),
  confirmPassword: yup
    .string()
    .required("Please confirm your new password")
    .min(8, "Password must have at least 8 characters")
    .max(128, "Password cannot be longer than 128 characters")
    .matches(/[^A-Za-z0-9]/, "Password must contain a special character")
    .matches(/[A-Z]/, "Password must contain an uppercase alphabet")
    .matches(/[a-z]/, "Password must contain a lowercase alphabet")
    .matches(numberRegExp, "Password must contain a number"),
  businessName: yup
    .string()
    .trim()
    .required("Please enter your business name")
    .min(2, "Business name must have at least 2 characters")
    .max(128, "Business name cannot have more than 128 characters"),
  businessPhone: yup
    .string()
    .trim()
    .matches(phoneRegExp, "Invalid Phone Number")
    .required("Please enter your phone number")
    .min(7, "Phone number must have at least 7 digits")
    .max(20, "Phone number cannot have more than 20 digits"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Please enter your email address")
    .min(5, "Email must have at least 5 characters")
    .max(128, "Email cannot have more than 128 characters"),
  businessAddress: yup
    .string()
    .trim()
    .required("Please enter your business address")
    .min(5, "Address must have at least 5 characters")
    .max(256, "Address cannot be longer than 256 characters"),
  businessCity: yup
    .string()
    .trim()
    .required("Please enter your business city")
    .min(3, "City must have at least 3 characters")
    .max(50, "City cannot be longer than 50 characters"),
  businessCategory: yup
    .string()
    .trim()
    .required("Please select a business sector"),
  businessType: yup.string().trim().required("Please select a business type"),
  countryCode: yup.string().trim().required("Please select your country"),
  dialCode: yup
    .string()
    .trim()
    .required("Please select the country code of this phone number"),
  shortName: yup
    .string()
    .required("Please enter your short name")
    .min(1, "Short name must have at least 1 character")
    .max(32, "Short name cannot be longer than 32 characters"),
  firstName: yup
    .string()
    .trim()
    .required("Please enter your first name")
    .min(1, "First name must have at least 1 character")
    .max(30, "First name cannot be longer than 30 characters"),
  lastName: yup
    .string()
    .trim()
    .required("Please enter your last name")
    .min(1, "Last name must have at least 1 character")
    .max(30, "Last name cannot be longer than 30 characters"),
  fullName: yup
    .string()
    .trim()
    .required("Please enter your name")
    .min(2, "Name must have at least 2 characters")
    .max(60, "Name cannot be longer than 60 characters"),
  contactMessage: yup
    .string()
    .trim()
    .required("Please enter your message")
    .min(10, "Message must have at least 10 characters")
    .max(300, "Message cannot be longer than 300 characters"),
  phoneNumber: yup
    .string()
    .trim()
    .required("Please enter your phone number")
    .min(7, "Phone number must have at least 7 digits")
    .max(20, "Phone number cannot have more than 20 digits")
    .matches(phoneRegExp, "Invalid Phone Number"),
  bvn: yup
    .string()
    .trim()
    .required("Please enter BVN")
    .min(11, "BVN must have exactly 11 digits")
    .max(11, "BVN must have exactly 11 digits")
    .matches(numberRegExp, "Invalid BVN"),
  accountNumber: yup
    .string()
    .trim()
    .required("Please enter account number")
    .matches(numberRegExp, "Account number must contain digits only")
    .min(10, "Account Number must have exactly 10 characters")
    .max(10, "Account Number must have exactly 10 characters"),
  bankCode: yup.string().trim().required("Please select bank"),
  businessState: yup.string().trim().required("Please select business state"),
  amount: yup
    .string()
    .trim()
    .required("Please enter amount")
    .matches(numberRegExp, "Invalid amount")
    .max(10, "Amount cannot have more than 10 characters"),
  interestRate: yup
    .string()
    .trim()
    .required("Please enter interest rate")
    .matches(numberRegExp, "Invalid interest rate")
    .max(5, "Interest rate cannot have more than 5 characters"),
  businessPurpose: yup
    .string()
    .trim()
    .required("Please tell us what your business does")
    .max(256, "Loan Purpose cannot have longer than 200 characters"),
  customerInformation: yup
    .string()
    .trim()
    .required("Please tell us about your customers ")
    .max(256, "Loan Purpose cannot have longer than 200 characters"),
  businessModel: yup
    .string()
    .trim()
    .required("Please choose your business model")
    .max(256, "Business Model cannot have longer than 256 characters"),
  riskLevel: yup
    .string()
    .trim()
    .required("Please enter risk level")
    .matches(numberRegExp, "Invalid risk level")
    .max(3, "Risk level cannot have more than 3 characters"),
  nplValue: yup
    .string()
    .trim()
    .required("Please enter you NPL value")
    .matches(numberRegExp, "Invalid NPL Value")
    .max(3, "NPL value cannot have more than 3 characters"),
  registrationType: yup
    .string()
    .trim()
    .required("Please select your registration type"),
  registrationNumber: yup
    .string()
    .trim()
    .required("Please enter borrower's RC Number")
    .min(5, "Registration Number must have at least 5 characters")
    .max(24, "Registration Number cannot have more than 24 characters"),
  booleanAnswer: yup
    .boolean()
    .oneOf([true, false], "Please choose an answer.")
    .required("Please choose an answer"),
  booleanAnswerNotRequired: yup
    .boolean()
    .oneOf([true, false], "Please choose an answer."),
  otp: yup
    .string()
    .required("Please enter OTP")
    .min(6, "OTP must have exactly 6 characters")
    .max(6, "OTP must have exactly 6 characters")
    .matches(numberRegExp, "Invalid OTP"),
  lendingTime: yup
    .number()
    .typeError("Number must be positive")
    .positive("Number must be positive")
    .min(1),

  lenderTrue: yup
    .boolean()
    .nullable()
    .oneOf([true, false], "Please choose an answer.")
    .required("Please choose an answer"),

  //shareholder validation
  shareholderType: yup
    .string()
    .trim()
    .required("Please select shareholder type"),
  shareholderName: yup
    .string()
    .trim()
    .required("Please enter shareholder name")
    .min(2, "Shareholder name must have at least 2 characters")
    .max(40, "Shareholder cannot have more than 24 characters"),
  numberOfShares: yup.string().required("Please enter number of shares"),
  shareholderCurrency: yup
    .string()
    .trim()
    .required("Please select shareholder currency"),
  valueofsharesamount: yup.string().required("Please enter value of shares"),
  percentOfShares: yup
    .number()
    .positive("Value must be positive")
    .required("Please enter percentage of shares"),
};
