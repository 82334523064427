enum ROUTES {
  DASHBOARD = "/",
  MAKE_LOAN_REQUEST = "make-loan-request",
  LOAN_APPLICATION = "loan-application",
  LOAN_OFFER = "loan-offer",
  PENDING_DEAL_SLIPS = "/pending-deal-slips",
  GET_STARTED = "/get-started",
  GET_STARTED_OFFER = "/get-started/offer-letter",
  GET_STARTED_ONBOARDING = "/get-started/onboarding",
  GET_STARTED_ONBOARDING_BUSINESS_INFO = "/get-started/onboarding/business-info",
  GET_STARTED_ONBOARDING_ELIGIBLITY_TEST = "/get-started/onboarding/eligiblity-test",
  GET_STARTED_ONBOARDING_UPLOAD_DOCUMENT = "/get-started/onboarding/upload-document",
  GET_STARTED_ONBOARDING_FINAL = "/get-started/onboarding/final",
  SETTINGS = "/settings",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_OF_USE = "/terms-of-use",
  WALLET_TERMS_OF_USE = "/wallet_terms_of_use",
  CONTACT_SUPPORT = "/contact-support",
  USER_MANAGEMENT = "/users-management",
  BORROWER_WIDGET = "/borrower-widget",
  AUDIT_LOGS = "/audit-logs",
  RESET_PASSWORD = "/reset-password",
  RESET_PASSWORD_VERIFY = "/reset-password/verify",
  RESET_PASSWORD_FINAL = "/reset-password/final",
  APPROVALS = "/approvals",
  INDIVIDUAL_LOAN_REQUEST = "/individual-loan-request",
  CORPORATE_LOAN_REQUEST = "/corporate-loan-request",
  BULK_LOAN_REQUEST = "/bulk-loan-request",
}
export default ROUTES;
