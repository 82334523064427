import * as yup from "yup";
import { numberRegExp, phoneRegExp } from "./yupValidators";

export const loanRequestSchema = {
  firstName: yup
    .string()
    .trim()
    .required("Please enter borrower's first name")
    .min(1, "First name must have at least 1 character")
    .max(30, "First name cannot be longer than 30 characters"),
  lastName: yup
    .string()
    .trim()
    .required("Please enter borrower's last name")
    .min(1, "Last name must have at least 1 character")
    .max(30, "Last name cannot be longer than 30 characters"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Please enter borrower's email address")
    .min(5, "Email must have at least 5 characters")
    .max(128, "Email cannot have more than 128 characters"),
  address: yup
    .string()
    .trim()
    .required("Please enter borrower's address")
    .min(5, "Address must have at least 5 characters")
    .max(256, "Address cannot be longer than 256 characters"),
  companyAddress: yup
    .string()
    .trim()
    .required("Please enter borrower's company address")
    .min(5, "Address must have at least 5 characters")
    .max(256, "Address cannot be longer than 256 characters"),
  idNumber: yup
    .string()
    .trim()
    .required("Please enter borrower's ID Number")
    .matches(numberRegExp, "Please enter a valid ID Number")
    .max(20, "ID Number cannot be longer than 20 characters"),
  businessRegistrationNumber: yup
    .string()
    .trim()
    .required("Please enter borrower's RC Number")
    .min(5, "RC Number must have at least 5 characters")
    .max(24, "RC Number cannot have more than 24 characters"),
  companyName: yup
    .string()
    .trim()
    .required("Please enter borrower's company's name")
    .min(5, "Company name must have at least 5 characters")
    .max(256, "Company name cannot have more than 256 characters"),
  bankName: yup.string().trim().required("Please select borrower's Bank"),
  accountNumber: yup
    .string()
    .trim()
    .required("Please enter borrower's account number")
    .matches(numberRegExp, "Account number must contain digits only")
    .min(10, "Account Number must have at least 10 characters")
    .max(20, "Account Number cannot have more than 20 characters"),
  accountName: yup
    .string()
    .trim()
    .required("Please enter borrower's account name")
    .min(3, "Account name must have at least 3 characters")
    .max(128, "Account name cannot have more than 128 characters"),
  borrowerGender: yup.string().trim().required("Borrower's gender is required"),
  borrowerPhoneNumber: yup
    .string()
    .trim()
    .required("Please enter borrower's phone number")
    .matches(phoneRegExp, "Phone number must contain digits only")
    .min(10, "Phone Number must have at least 10 characters")
    .max(20, "Phone Number cannot have more than 20 characters"),
  borrowerCity: yup
    .string()
    .trim()
    .required("Please enter borrower's city")
    .min(2, "City must have at least 2 characters")
    .max(128, "City cannot have more than 128 characters"),
  borrowerState: yup
    .string()
    .trim()
    .required("Please enter borrower's state")
    .min(2, "State must have at least 2 characters")
    .max(128, "State cannot have more than 128 characters"),
  companyCity: yup
    .string()
    .trim()
    .required("Please enter borrower's company city")
    .min(2, "City must have at least 2 characters")
    .max(128, "City cannot have more than 128 characters"),
  companyState: yup
    .string()
    .trim()
    .required("Please enter borrower's company state")
    .min(2, "State must have at least 2 characters")
    .max(128, "State cannot have more than 128 characters"),
  productId: yup
    .string()
    .trim()
    .required("Please choose a product")
    .matches(numberRegExp, "Invalid Product Selected"),
  sourceOfRepayment: yup
    .string()
    .trim()
    .required("Please choose a source of repayment"),
  loanAmount: yup.string().trim().required("Please enter loan amount"),
  // .matches(numberRegExp, "Invalid loan amount")
  loanTenure: yup
    .string()
    .trim()
    .required("Please enter loan tenure")
    .matches(numberRegExp, "Invalid loan tenure")
    .max(3, "Loan tenure cannot have more than 3 characters"),
  loanReferenceNumber: yup
    .string()
    .trim()
    .required("Please enter loan reference number")
    .max(256, "Loan reference number cannot have more than 256 characters"),
  borrowerSector: yup.string().trim().required("Please choose sector"),
  repaymentStartDate: yup
    .string()
    .trim()
    .required("Please choose a repayment start date"),
  dateOfBirth: yup.string().trim().required("Please choose a start date"),
  interestRate: yup
    .string()
    .trim()
    .required("Please enter interest rate for this loan")
    .matches(numberRegExp, "Invalid interest rate")
    .max(5, "Interest rate cannot have more than  5 characters"),
  loanPurpose: yup
    .string()
    .trim()
    .max(128, "Loan Purpose cannot have longer than 128 characters"),
  country: yup
    .string()
    .trim()
    .required("Please select borrower's country")
    .max(128, "Country cannot have more than 128 characters"),
  state: yup
    .string()
    .trim()
    .required("Please select borrower's state")
    .max(128, "State cannot have more than 128 characters"),
};
