import React, { Fragment, useEffect, useState } from "react";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import { useHistory, withRouter } from "react-router-dom";
import Loader from "../../components/Loader/Loader.component";
import { errorHandler } from "../../helpers/errorHandler";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import useAggregatorProducts from "../../custom-hooks/useAggregatorProducts";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import moment from "moment";
import { loanRequestSchema } from "../../helpers/loanRequestSchema";
import useABorrowerProfileDetails from "../../custom-hooks/useABorrowerProfileDetails";
import { useAuth } from "../../Context/auth.context";
import { appInsights } from "../../components/AppInsight/AppInsight";
import useAdvanclyCurrentCountries from "../../custom-hooks/useAdvanclyCurrentCountries";
import LoaderColored from "../../components/Loader/LoaderColored";
import { handleFormatAmountNoCurrency } from "../../helpers/formatterNoDecimal";
import LoanOfferModalExistingLoanRequest from "./LoanOfferModalExistingLoanRequest";

const schema = yup.object().shape({
  productId: loanRequestSchema.productId,
  loanAmount: loanRequestSchema.loanAmount,
  loanTenure: loanRequestSchema.loanTenure,
  interestRate: loanRequestSchema.interestRate,
  country: loanRequestSchema.country,
});

const ExistingBorrowerLoanRequest = ({ match }) => {
  //loan offer modal state
  const [showLoanOfferModal, setShowLoanOfferModal] = useState(false);
  const [loanOfferError, setLoanOfferError] = useState(false);
  const [loanOfferSuccess, setLoanOfferSucess] = useState(false);
  const [loanOfferLoading, setLoanOfferLoading] = useState(false);
  const [loanTenureInDays, setLoanTenureInDays] = useState();
  const [loanCurrency, setLoanCurrency] = useState();
  const [totalPrincipal, setTotalPrincipal] = useState();
  const [totalRepayment, setTotalRepayment] = useState();
  const [totalInterestCharged, setTotalInterestCharged] = useState();
  //end of loan offer modal state
  const { aggregatorDetails } = useAuth();
  const [error, setError] = useState(false);
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countrycode, setCountryCode] = useState(null);
  const [loanTenure, setLoanTenure] = useState(null);
  const [loanTenureMax, setLoanTenureMax] = useState(null);
  const [productId, setProductId] = useState(null);
  const [productName, setProductName] = useState(null);
  const [requestBody, setRequestBody] = useState(null);
  const [amountValue, setAmountValue] = useState("");
  const scrollToTop = () => {
    document.getElementById("topOfPage").scrollIntoView({
      behavior: "smooth",
    });
  };
  const { register, handleSubmit, errors, reset, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: countriesError,
  } = useAdvanclyCurrentCountries();
  const countryHandler = (e) => setCountryCode(e.target.value);
  // const country = watch("country");
  const history = useHistory();

  // const resolveCountryInfo = countries?.find(
  //   ({ iso2 }) => iso2 === (country || countrycode)
  // );
  // const { data: states, isLoading: isStatesLoading } = useCountryStates({
  //   countryISO: resolveCountryInfo?.iso2,
  // });
  const {
    data: productsData,
    status: productsStatus,
    error: productsError,
  } = useAggregatorProducts({ country_code: countrycode });
  const {
    data: borrowerProfile,
    status: borrowerStatus,
    error: borrowerError,
  } = useABorrowerProfileDetails({ id: match.params.id });
  let now = moment();
  const onSubmit = async (data) => {
    const { productId, loanAmount, loanTenure, interestRate, country } = data;

    const reqBody = {
      product_id: Number(productId),
      loan_amount: +handleFormatAmountNoCurrency(String(loanAmount)),
      loan_tenure: Number(loanTenure),
      annual_interest_rate: Number(interestRate),
      aggregator_id: Number(aggregatorDetails?.aggregator_id),
      country_code: country,
    };

    setRequestBody(reqBody);
    //Post for Loan Schedule
    const reqBodyForLoanSchedule = {
      productId: Number(productId),
      loanTenor: Number(loanTenure),
      principalAmount: +handleFormatAmountNoCurrency(String(loanAmount)),
      interest: Number(interestRate),
      loanEffectiveDate: now.format(),
    };
    setLoanOfferLoading(true);
    setLoanOfferError(false);
    setLoanOfferSucess(false);

    try {
      const data = await postDataWithDotNet(
        apiEndpoints.LOAN_OFFER_SCHEDULE,
        reqBodyForLoanSchedule
      );

      setLoanOfferLoading(false);
      if (data.status === true) {
        setLoanOfferSucess(data.message);
        setTimeout(() => {
          setLoanOfferSucess(false);
        }, 5000);
        setLoanTenureInDays(data?.data?.loanTermInDays);
        setLoanCurrency(data?.data?.currency?.code);
        setTotalPrincipal(data?.data?.totalPrincipalExpected);
        setTotalRepayment(data?.data?.totalRepaymentExpected);
        setTotalInterestCharged(data?.data?.totalInterestCharged);
        setShowLoanOfferModal(true);
      } else {
        setLoanOfferError(data.message);
        setTimeout(() => {
          setLoanOfferError(null);
        }, 5000);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "ExistingBorrowerLoanRequest.jsx" },
      });
      setLoanOfferLoading(false);
      if (error?.response?.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        // setLoanOfferError(errorHandler(error));
        setLoanOfferError(errorHandler(error?.response?.data?.message));

        scrollToTop();
      }
    }
    //loan offer is displayed first

    // setLoading(true);
    // setError(false);
    // setSucess(false);

    // try {
    //   const data = await postDataWithDotNet(
    //     apiEndpoints.loanApplicationForExistingBorrower,
    //     reqBody
    //   );
    //   setLoading(false);
    //   scrollToTop();
    //   if (data.status === true) {
    //     setSucess(
    //       "The loan request is successful and is undergoing approval. The borrower's bank account will be credited if the loan is approved."
    //     );
    //     reset();
    //     setTimeout(() => {
    //       setSucess(false);
    //     }, 5000);
    //   } else {
    //     setError(data.message);
    //     setTimeout(() => {
    //       setError(null);
    //     }, 5000);
    //   }
    // } catch (error) {
    //   appInsights.trackException({
    //     exception: error,
    //     properties: {
    //       fileName: "ExistingBorrowerLoanRequest.jsx",
    //     },
    //   });
    //   setLoading(false);
    //   scrollToTop();
    //   setError(errorHandler(error));
    // }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "ExistingBorrowerLoanRequest.jsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (productId) {
      const product = productsData?.find(
        (eachProduct) =>
          Number(eachProduct.id) === Number(productId) && eachProduct
      );
      setValue("loanTenure", product?.maximum_tenor);
      setLoanTenureMax(product?.maximum_tenor);
      // setInterestRate(product?.interest_rate);
      setValue("interestRate", product?.interest_rate);
      setProductName(product?.product_name);
    }
  }, [productId, productsData, setValue]);

  const productHandlerId = (e) => {
    setProductId(e.target.value);
  };

  const resetAmountValue = () => {
    setAmountValue("");
  };
  if (borrowerStatus === "loading") return <PageLoader />;
  return (
    <Fragment>
      <div className="card mb-5 " id="topOfPage">
        <div className="card-body p-4">
          <h5 className="page-subtitle text-center">
            Existing Borrower Loan Request
          </h5>
          <p>
            <span className="font-weight-bold">Borrower's Name: </span>
            {borrowerProfile?.first_name} {borrowerProfile?.last_name}
          </p>
          <p>
            <span className="font-weight-bold">Borrower's Email:</span>{" "}
            {borrowerProfile?.email}
          </p>
          {success && <p className="alert alert-success small">{success}</p>}
          {error && <p className="alert alert-danger small">{error}</p>}
          {productsError && (
            <p className="alert alert-danger small">
              {errorHandler(productsError)}
            </p>
          )}
          {loanOfferError && (
            <p className="alert alert-danger small">{loanOfferError}</p>
          )}

          {countriesError && (
            <p className="alert alert-danger small">
              {errorHandler(countriesError)}
            </p>
          )}
          {borrowerError && (
            <p className="alert alert-danger small">
              {errorHandler(borrowerError)}
            </p>
          )}
          <p className="text-danger small text-center mb-5">
            Fields with asterisk (*) are required
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12 col-lg-6">
                <CustomSelectDropdown
                  reference={register}
                  label="Country"
                  name="country"
                  errors={errors.country}
                  showRequiredIcon={true}
                  extraLabel={isLoadingCountries ? "(Loading...)" : null}
                  onChange={countryHandler}
                >
                  <option value="">Country</option>
                  {countries?.map(({ name, id, iso2 }) => {
                    return (
                      <option value={iso2} key={id}>
                        {name}
                      </option>
                    );
                  })}
                </CustomSelectDropdown>
              </div>
              {/* <div className="col-12 col-lg-6">
                <CustomSelectDropdown
                  reference={register}
                  label="State"
                  name="state"
                  errors={errors.state}
                  showRequiredIcon={true}
                  extraLabel={isStatesLoading ? "(Loading...)" : ""}
                >
                  <option value="">State</option>;
                  {states?.map(({ name, id }) => {
                    return (
                      <option value={name} key={id}>
                        {name}
                      </option>
                    );
                  })}
                </CustomSelectDropdown>
              </div> */}
              <div className="col-12 col-lg-6 mb-3">
                <CustomSelectDropdown
                  reference={register}
                  label="Loan Product"
                  name="productId"
                  errors={errors.productId}
                  showRequiredIcon={true}
                  onChange={productHandlerId}
                >
                  <option value="">Select Product</option>
                  {productsData &&
                    productsData.length &&
                    productsData.map((product) => (
                      <option key={product.id} value={product.id}>
                        {product.product_name}
                      </option>
                    ))}
                </CustomSelectDropdown>
                {productsStatus === "loading" ? (
                  <div className=" d-flex justify-content-end">
                    {" "}
                    <LoaderColored />{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <CustomInputField
                  type="number"
                  reference={register}
                  label="Loan Amount"
                  value={amountValue}
                  placeholder="Enter loan amount"
                  name="loanAmount"
                  errors={errors.loanAmount}
                  showRequiredIcon={true}
                  enableSeparator
                  onChange={(values) => {
                    const { formattedValue } = values;
                    setAmountValue(formattedValue);
                  }}
                />
              </div>
              {/* <div className="col-12 col-lg-6 mb-3">
                <CustomInputField
                  type="number"
                  maxLength="3"
                  reference={register}
                  label="Loan Tenure"
                  name="loanTenure"
                  placeholder="Enter Loan Tenure"
                  errors={errors.loanTenure}
                  extraLabel="(in days)"
                  showRequiredIcon={true}
                />
              </div> */}
              <div className="col-12 col-lg-6">
                <CustomInputField
                  type="number"
                  maxLength="3"
                  reference={register}
                  label="Loan Tenure"
                  name="loanTenure"
                  min="10"
                  max={loanTenureMax}
                  placeholder="Enter Loan Tenure"
                  errors={errors.loanTenure}
                  extraLabel="(in days)"
                  showRequiredIcon={true}
                  defaultValue={loanTenure}
                  // value={loanTenure || ""}
                  onChange={(e) => setLoanTenure(e.target.value)}
                  readOnly={!productId}
                />
              </div>

              <div className="col-12 col-lg-6 mb-3">
                <CustomInputField
                  type="number"
                  maxLength="5"
                  step="any"
                  reference={register}
                  label="Annual Interest Rate"
                  name="interestRate"
                  placeholder="Enter Annual Interest Rate"
                  errors={errors.interestRate}
                  extraLabel="(in %)"
                  showRequiredIcon={true}
                  // defaultValue={interestRate || ""}
                  readOnly={productId}
                />
              </div>

              <div className="col-sm-12 mb-12">
                <button
                  type="submit"
                  className="btn advancly-btn btn-sm transition-3d-hover"
                >
                  Send Request
                  {loanOfferLoading && <Loader />}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showLoanOfferModal ? (
        <LoanOfferModalExistingLoanRequest
          loanOfferError={loanOfferError}
          loanOfferSuccess={loanOfferSuccess}
          loanOfferLoading={loanOfferLoading}
          loanTenureInDays={loanTenureInDays}
          loanCurrency={loanCurrency}
          totalPrincipal={totalPrincipal}
          totalRepayment={totalRepayment}
          totalInterestCharged={totalInterestCharged}
          setShowLoanOfferModal={setShowLoanOfferModal}
          success={success}
          error={error}
          loading={loading}
          setLoading={setLoading}
          setError={setError}
          setSucess={setSucess}
          requestBody={requestBody}
          setRequestBody={setRequestBody}
          reset={reset}
          scrollToTop={scrollToTop}
          productName={productName}
          borrowerProfile={borrowerProfile}
          resetAmountValue={resetAmountValue}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default withRouter(ExistingBorrowerLoanRequest);
