import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "../LoanApplication.module.scss";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import Loader from "../../Loader/Loader.component";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData, putData } from "../../../apis/apiMethods";
import CollateralExistingList from "./CollateralExistingList";
import { useForm } from "react-hook-form";

const schema = yup.object().shape({
  collateralvalue: yup.string().required(),
  registeredowner: yup.string().required(),
  locationSourceProvider: yup.string().required(),
  developmentUseOfLand: yup.string().required(),
  commentsOnSimilarAssets: yup.string().required(),
  availabilityOfDocumentTitle: yup.string().required(),
  financialInstrumentType: yup.string().required(),
});
interface IreqBody {
  collateralvalue: string;
  registeredowner: string;
  locationSourceProvider: string;
  developmentUseOfLand: string;
  commentsOnSimilarAssets: string;
  availabilityOfDocumentTitle: string;
  financialInstrumentType: string;
}

const Collateral = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [editCollateral, setEditCollateral] = useState(false);
  const [addAnotherCollateral, setAddAnotherLoanInfomation] = useState(false);
  const [isExistingCollateralLoading, setIsExistingCollateralLoading] =
    useState(false);
  const { register, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const CollateralEndpoint = async (bodyResponse: IreqBody) => {
    try {
      setLoading(true);
      setError(null);
      if (editCollateral) {
        const res = await putData(
          apiEndpoints.FUNDER_PEOPLE,
          bodyResponse,
          true
        );
        setSuccess(res?.message);
      } else if (addAnotherCollateral) {
        const res = await postData(
          apiEndpoints.FUNDER_PEOPLE,
          bodyResponse,
          true
        );
        setSuccess(res?.message);
        setLoading(false);

        reset();
        setTimeout(() => {
          setSuccess(null);
        }, 5000);
      } else {
        const res = await postData(
          apiEndpoints.FUNDER_PEOPLE,
          bodyResponse,
          true
        );
        setSuccess(res?.message);
        setLoading(false);

        reset();
        setTimeout(() => {
          setSuccess(null);
        }, 5000);
      }
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "Collateral.tsx",
        },
      });
      setLoading(false);
      setSuccess(null);
      setError(errorHandler(err));
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };
  const onAddCollateralHandler = (data: IreqBody) => {
    const {
      collateralvalue,
      registeredowner,
      locationSourceProvider,
      developmentUseOfLand,
      commentsOnSimilarAssets,
      availabilityOfDocumentTitle,
      financialInstrumentType,
    } = data;
    const ReqBody = {
      collateralvalue,
      registeredowner,
      locationSourceProvider,
      developmentUseOfLand,
      commentsOnSimilarAssets,
      availabilityOfDocumentTitle,
      financialInstrumentType,
    };
    CollateralEndpoint(ReqBody);
  };

  const onContinueCollateralHandler = async (data: IreqBody) => {
    const {
      collateralvalue,
      registeredowner,
      locationSourceProvider,
      developmentUseOfLand,
      commentsOnSimilarAssets,
      availabilityOfDocumentTitle,
      financialInstrumentType,
    } = data;
    const ReqBody = {
      collateralvalue,
      registeredowner,
      locationSourceProvider,
      developmentUseOfLand,
      commentsOnSimilarAssets,
      availabilityOfDocumentTitle,
      financialInstrumentType,
    };
    CollateralEndpoint(ReqBody);
  };
  return (
    <div className={`${styles.center_container} `}>
      <div className="py-3">
        <h2 className={`${styles.loanInformationHeading}`}>Collateral</h2>
        <p className={`${styles.InfoParagraph}`}>
          Select and upload multiple required Financial Information provided
        </p>
      </div>

      <CollateralExistingList
        isExistingCollateralLoading={isExistingCollateralLoading}
        setEditCollateral={setEditCollateral}
      />
      <form>
        <div className="row">
          <div className="col-12">
            <CustomInputField
              type="text"
              reference={register}
              name="collateralvalue"
              placeholder="E.g. Market Value, Forced Sale Value"
              label="Value"
              errors={errors.collateralvalue}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              reference={register}
              name="registeredowner"
              placeholder="E.g. Market Value, Forced Sale Value"
              label="Registered owner "
              errors={errors.registeredowner}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              reference={register}
              name="locationSourceProvider"
              placeholder="E.g. Market Value, Forced Sale Value"
              label="Location, Source, Provider, Issuer "
              errors={errors.locationSourceProvider}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              reference={register}
              name="developmentUseOfLand"
              placeholder="E.g. Market Value, Forced Sale Value"
              label="Developments and use of land"
              errors={errors.developmentUseOfLand}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              reference={register}
              name="commentsOnSimilarAssets"
              placeholder="E.g. Market Value, Forced Sale Value"
              label="Comment on similar assets"
              errors={errors.commentsOnSimilarAssets}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              reference={register}
              name="availabilityOfDocumentTitle"
              placeholder="E.g. Market Value, Forced Sale Value"
              label="Availability of document of title"
              errors={errors.availabilityOfDocumentTitle}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              reference={register}
              name="financialInstrumentType"
              placeholder="E.g. Market Value, Forced Sale Value"
              label="Financial Instrument Type"
              errors={errors.financialInstrumentType}
            />
          </div>
          <div className="col-12">
            <button
              type="submit"
              className="fa fa-plus  advancly-text-btn my-3"
              onClick={handleSubmit(onAddCollateralHandler)}
            >
              <span className={` pl-1`}>
                Add another Collateral {loading && <Loader />}
              </span>
            </button>
          </div>
          <div className={`col-12`}>
            <button
              type="submit"
              className="advancly-btn  transition-3d-hover m-2"
              onClick={handleSubmit(onContinueCollateralHandler)}
              disabled={loading}
            >
              Continue
              {loading && <Loader />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Collateral;
