import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import styles from "../LoanApplication.module.scss";
import { loanRequestSchema } from "../../../helpers/loanRequestSchema";
import { useForm } from "react-hook-form";
import Loader from "../../Loader/Loader.component";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData, putData } from "../../../apis/apiMethods";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import LoanInformationExistingList from "./LoanInformationExistingList";
import useLoanProductDetails from "../../../custom-hooks/useLoanProductDetail";
import LoaderColored from "../../Loader/LoaderColored";
import useGeneralCurrencies from "../../../custom-hooks/useGeneralCurrencies";
import { IstepLoanApp } from "../LoanApplicationMain";
import { handleFormatAmountNoCurrency } from "../../../helpers/formatterNoDecimal";
import CustomNestedInputField from "../../CustomHTMLElements/CustomNestedInputField";
import { useAuth } from "../../../Context/auth.context";
import CustomTextArea from "../../CustomHTMLElements/CustomTextArea";
const schema = yup.object().shape({
  loanAmount: loanRequestSchema.loanAmount,
  interestRate: loanRequestSchema.interestRate,
  loanProduct: loanRequestSchema.productId,
  loanTenure: loanRequestSchema.loanTenure,
  sourceOfRepayment: loanRequestSchema.sourceOfRepayment,
});
interface IreqBody {
  loanAmount: string;
  currency: string;
  loanProduct: string;
  loanTenure: string;
  interestRate: string;
  sourceOfRepayment: string;
}

const LoanInformation = ({
  handlePrev,
  handleNext,
  scrollToTop,
}: IstepLoanApp) => {
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState(null);
  const [AddMoreInformationLoading, setAddMoreInformationLoading] =
    useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [editLoanInformation, setEditLoanInformation] = useState(false);
  const [addAnotherLoanInformation, setAddAnotherLoanInformation] =
    useState<Boolean>(false);
  const [selectedCurrencyValue, setSelectedCurrencyValue] = useState("");
  const [loanTenureMax, setLoanTenureMax] = useState(null);

  const [
    isExistingLoanInformationLoading,
    setIsExistingLoanInformationLoading,
  ] = useState(false);
  const [amountValue, setAmountValue] = useState("");
  const handleSelectChange = (event: any) => {
    setSelectedCurrencyValue(event.target.value);
  };
  const { register, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const { setCurrentLoanApplicationId } = useAuth();

  const {
    data: loanProductDetailsData,
    status: loanProductDetailsStatus,
    error: loanProductDetailsErrors,
  } = useLoanProductDetails();

  const productHandlerId = (e: any) => {
    setProductId(e.target.value);
  };

  useEffect(() => {
    if (productId) {
      const product = loanProductDetailsData?.find(
        (eachProduct: { id: number }) =>
          Number(eachProduct.id) === Number(productId) && eachProduct
      );
      setValue("loanTenure", product?.maximum_tenor);
      setLoanTenureMax(product?.maximum_tenor);
      setValue("interestRate", product?.maximum_interest_rate);
    }
  }, [productId, loanProductDetailsData, setValue]);
  const {
    data: currencyData,
    status: currencyStatus,
    error: currencyErrors,
  } = useGeneralCurrencies();
  const resetAmountValue = () => {
    setAmountValue("");
  };
  const onAddLoanInformationHandler = async (data: IreqBody) => {
    const {
      loanAmount,
      loanProduct,
      loanTenure,
      sourceOfRepayment,
      interestRate,
      currency,
    } = data;
    const ReqBody = {
      repayment_source: sourceOfRepayment,
      loan_product_details: [
        {
          proposed_loan_product_detail_id: Number(loanProduct),
          currency: currency ? currency : selectedCurrencyValue,
          proposed_amount: +handleFormatAmountNoCurrency(String(loanAmount)),

          proposed_interest_rate: Number(interestRate),
          proposed_tenor: Number(loanTenure),
        },
      ],
    };
    try {
      setAddMoreInformationLoading(true);
      setError(null);
      if (editLoanInformation) {
        const res = await putData(
          apiEndpoints.LOAN_APPLICATIONS,
          ReqBody,
          true
        );
        setAddMoreInformationLoading(false);
        setSuccess(res?.message);
        setCurrentLoanApplicationId(res?.data?.loan_application_id);

        if (scrollToTop) {
          scrollToTop();
        }
        setTimeout(() => {
          if (addAnotherLoanInformation) {
            reset();
          } else {
            if (handleNext) {
              handleNext();
            }
          }
          setSuccess(null);
        }, 2000);
      } else {
        const res = await postData(
          apiEndpoints.LOAN_APPLICATIONS,
          ReqBody,
          true
        );
        setAddMoreInformationLoading(false);
        setSuccess(res?.message);
        setCurrentLoanApplicationId(res?.data?.loan_application_id);
        if (scrollToTop) {
          scrollToTop();
        }
        setTimeout(() => {
          setSuccess(null);
          setAddAnotherLoanInformation(false);
          reset();
          resetAmountValue();
        }, 2000);
      }
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "LoanInformation.tsx",
        },
      });
      setAddMoreInformationLoading(false);
      setSuccess(null);
      setError(errorHandler(err));
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const onContinueLoanAmountHandler = async (data: IreqBody) => {
    const {
      loanAmount,
      loanProduct,
      loanTenure,
      sourceOfRepayment,
      interestRate,
      currency,
    } = data;
    const ReqBody = {
      repayment_source: sourceOfRepayment,
      loan_product_details: [
        {
          proposed_loan_product_detail_id: Number(loanProduct),
          currency: currency ? currency : selectedCurrencyValue,
          proposed_amount: +handleFormatAmountNoCurrency(String(loanAmount)),
          proposed_interest_rate: Number(interestRate),
          proposed_tenor: Number(loanTenure),
        },
      ],
    };
    try {
      setLoading(true);
      setError(null);
      if (editLoanInformation) {
        const res = await putData(
          apiEndpoints.LOAN_APPLICATIONS,
          ReqBody,
          true
        );
        setLoading(false);
        setSuccess(res?.message);
        setCurrentLoanApplicationId(res?.data?.loan_application_id);

        if (scrollToTop) {
          scrollToTop();
        }
        setTimeout(() => {
          if (addAnotherLoanInformation) {
            reset();
          } else {
            if (handleNext) {
              handleNext();
            }
          }
          setSuccess(null);
        }, 2000);
      } else {
        const res = await postData(
          apiEndpoints.LOAN_APPLICATIONS,
          ReqBody,
          true
        );
        setLoading(false);
        setSuccess(res?.message);
        setCurrentLoanApplicationId(res?.data?.loan_application_id);

        if (scrollToTop) {
          scrollToTop();
        }

        setTimeout(() => {
          if (handleNext) {
            handleNext();
          }
          setSuccess(null);
          setAddAnotherLoanInformation(false);
          reset();
        }, 2000);
      }
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "LoanInformation.tsx",
        },
      });
      setLoading(false);
      setSuccess(null);
      setError(errorHandler(err));
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  return (
    <div className={`${styles.center_container} `}>
      <div className="py-3">
        <h2 className={`${styles.loanInformationHeading}`}>Loan Information</h2>
        <p className={`${styles.InfoParagraph}`}>
          Update the information stated below
        </p>
      </div>
      <div>
        {loanProductDetailsErrors && (
          <p className="alert alert-danger small">
            {errorHandler(loanProductDetailsErrors)}
          </p>
        )}
        {currencyErrors && (
          <p className="alert alert-danger small">
            {errorHandler(currencyErrors)}
          </p>
        )}
        {success && <p className="alert alert-success small">{success}</p>}
        {error && <p className="alert alert-danger small">{error}</p>}
      </div>
      <LoanInformationExistingList
        isExistingLoanInformationLoading={isExistingLoanInformationLoading}
        setEditLoanInformation={setEditLoanInformation}
      />
      <form>
        <div className="row">
          <div className="col-12">
            <CustomNestedInputField
              type="number"
              reference={register}
              name="loanAmount"
              value={amountValue}
              placeholder="E.g N240,000,000,000"
              label="Amount"
              errors={errors.loanAmount}
              enableSeparator
              onChange={(values: any) => {
                const { formattedValue } = values;
                setAmountValue(formattedValue);
              }}
            >
              <select
                name="currency"
                ref={register}
                value={selectedCurrencyValue}
                onChange={handleSelectChange}
                style={{ backgroundColor: "#D1CFD6", color: "#170D33" }}
              >
                {currencyData &&
                  currencyData.length &&
                  currencyData?.map(
                    ({ currency_code }: { currency_code: string }) => (
                      <option key={currency_code} value={currency_code}>
                        {currency_code}
                      </option>
                    )
                  )}
              </select>
            </CustomNestedInputField>
            {currencyStatus === "loading" && (
              <div className=" d-flex justify-content-end">
                {" "}
                <LoaderColored />{" "}
              </div>
            )}
          </div>
          <div className="col-12">
            <CustomSelectDropdown
              reference={register}
              name="loanProduct"
              label="Loan Product"
              errors={errors.loanProduct}
              onChange={productHandlerId}
            >
              <option value="">Select Product</option>
              {loanProductDetailsData &&
                loanProductDetailsData?.length &&
                loanProductDetailsData?.map(
                  ({ id, name }: { id: number; name: string }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  )
                )}
            </CustomSelectDropdown>
            {loanProductDetailsStatus === "loading" && (
              <div className=" d-flex justify-content-end">
                {" "}
                <LoaderColored />{" "}
              </div>
            )}
          </div>
          <div className="col-12">
            <CustomInputField
              type="number"
              maxLength="3"
              min={10}
              max={loanTenureMax}
              reference={register}
              name="loanTenure"
              placeholder="E.g 230"
              label="Tenor"
              extraLabel="(in days)"
              showRequiredIcon={true}
              errors={errors.loanTenure}
            />
          </div>
          <div className="col-12 ">
            <CustomInputField
              type="number"
              maxLength="5"
              step="any"
              reference={register}
              label=" Interest Rate"
              name="interestRate"
              errors={errors.interestRate}
              extraLabel="(in %)"
              showRequiredIcon={true}
              readOnly={productId ? true : false}
            />
          </div>
          <div className="col-12">
            <CustomTextArea
              reference={register}
              name="sourceOfRepayment"
              label="Source of Repayment"
              errors={errors.sourceOfRepayment}
            />
          </div>
          <div className="col-12">
            <button
              type="submit"
              className="fa fa-plus  advancly-text-btn my-3"
              onClick={handleSubmit(onAddLoanInformationHandler)}
              disabled={AddMoreInformationLoading}
            >
              <span className={` pl-1`}>
                Add another Loan Information{" "}
                {AddMoreInformationLoading && <LoaderColored />}
              </span>
            </button>
          </div>
          <div className={`col-12`}>
            <button
              type="submit"
              className="advancly-btn  transition-3d-hover m-2"
              onClick={handleSubmit(onContinueLoanAmountHandler)}
              disabled={loading}
            >
              Continue
              {loading && <Loader />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoanInformation;
